import {
  Alert,
  Box,
  Button,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as React from "react";
import { useState } from "react";
import { getMawbInfo } from "../API/SpcAPI";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import "../Test.css";
import SitesMobileBack from "../images/grey_img.jpg";
import { CssTextField, SearchButton } from "../util/CovMui";

export default function SpcMainMobile() {
  const {
    setErrFlag,
    setLoadFlag,
    saveFlag,
    setSaveFlag,
    deleteFlag,
    setDeleteFlag,
  } = useContext(AppContext);
  const [mawbError, setMawbError] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [mawb, setMawb] = useState("");

  const navigator = useNavigate();

  const handleClose = () => setSearchFlag(false);
  const handlePostClick = () => {
    navigator(`/SpcInfo/I/${mawb}`);
  };
  const handleMawbChange = (event) => {
    let value = event.target.value.replace(/\D/g, "").replace("-", "");
    if (value.length === 12) {
      return;
    }

    if (value.length <= 7) {
      value = value.replace(/^(\d{3})(\d{1,4})$/, "$1-$2");
    }

    if (value.length <= 11) {
      value = value.replace(/^(\d{3})(\d{4})(\d{1,4})$/, "$1-$2-$3");
    }

    setMawb(value);
  };

  const handleSearchClick = () => {
    if (mawb.replace(/-/g, "").length === 11) {
      setMawbError(false);

      getMawbInfo(mawb, setErrFlag, setLoadFlag)
        .then((result) => {
          if (result === "NODATA") {
            //mawb조회 시 신규일때로직
            setSearchFlag(true);
          } else {
            //mawb 조회 시 자료있을때 로직
            navigator(`/SpcInfo/U/${mawb}`);
          }
        })
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    } else {
      setMawbError(true);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveFlag(false);
  };

  const handleDeleteClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeleteFlag(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F7F8",
        minHeight: "inherit",
      }}
    >
      <Box
        sx={{
          width: "90%",
          paddingTop: "20%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Paper
          sx={{
            alignItems: "center",
            mt: 3,
            pl: 2,
            pr: 2,
            backgroundColor: "rgba(255, 255, 255, 70%)",
          }}
        >
          <TableContainer>
            <Table className="tableGroup1">
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                      width: "10%",
                      textAlign: "center",
                      mr: 2,
                    }}
                  >
                    MAWB
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CssTextField
                      id="mawbText"
                      variant="standard"
                      value={mawb}
                      required
                      onChange={handleMawbChange}
                      error={mawbError}
                      helperText={mawbError ? "MAWB를 확인하세요." : ""}
                      autoComplete="off"
                      inputProps={{ sx: { textAlign: "left !important" } }}
                    />

                    <SearchButton
                      variant="outlined"
                      sx={{
                        ml: 1,
                        pl: 1,
                        whiteSpace: "nowrap",
                      }}
                      onClick={handleSearchClick}
                      endIcon={<SearchIcon />}
                    >
                      조회
                    </SearchButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Modal open={searchFlag} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              backgroundColor: "#E5F6FD",
              p: 2,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <InfoOutlinedIcon sx={{ mr: 2, color: "#2A9BD9" }} />
                <Typography sx={{ fontFamily: "Noto Sans KR" }}>
                  등록되지 않은 화물입니다. <br /> 신규 등록 하시겠습니까?
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Button onClick={handlePostClick}>신규 등록</Button>
                <Button onClick={handleClose}>닫기</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Snackbar
        open={saveFlag}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity="success"
          onClose={handleAlertClose}
          sx={{ width: "100%" }}
        >
          화물이 등록이 완료되었습니다.
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteFlag}
        autoHideDuration={3000}
        onClose={handleDeleteClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity="success"
          onClose={handleDeleteClose}
          sx={{ width: "100%" }}
        >
          게시물이 삭제되었습니다.
        </Alert>
      </Snackbar>
    </Box>
  );
}
