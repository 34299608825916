import { Box, Button, CssBaseline, Divider } from "@mui/joy";
import { AppBar, Paper, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import "./css/AppBar.css";
import logo from "./images/AACT_logo2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";
export default function MainAppBar() {
  const history = useNavigate();
  const location = useLocation();
  const [isFixed, setIsFixed] = useState(true);
  const { setErrFlag, setLoadFlag, setCompleteCode } = useContext(AppContext);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset <= 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const reset = () => {
    setErrFlag({ code: "N", data: "" });
    setLoadFlag(false);
    setCompleteCode(false);
  };
  const linkHome = () => {
    reset("Y");
    history("/");
  };
  const linkTrack = () => {
    reset("Y");
    history("/Tracking");
  };
  const linkInquery = () => {
    reset("Y");
    history("/Inquery");
  };
  const linkSpc = () => {
    reset("Y");
    history("/Spc");
  };
  const linkFlight = () => {
    reset("Y");
    history("/Flight");
  };
  const linkLocation = () => {
    reset("Y");
    history("/Location");
  };
  const linkSites = () => {
    reset("Y");
    history("/Sites");
  };

  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box>
      <CssBaseline />
      <AppBar
        position={isFixed ? "fixed" : "static"}
        sx={{
          top: 0,
          bottom: "auto",
          display: isFixed ? "block" : "none",
          backgroundColor: "rgba(255, 255, 255, 0)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 6,
            pb: 1,
          }}
        >
          <Box sx={{ width: "8%", ml: "15vw" }}>
            <img
              src={logo}
              width={"100%"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{ cursor: isHovered ? "pointer" : "default" }}
              onClick={linkHome}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                color: "black",
                display: "contents",
              }}
            >
              <Typography
                onClick={linkTrack}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Noto Sans KR",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                화물 추적
              </Typography>

              <Typography
                onClick={linkInquery}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Noto Sans KR",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  ml: "2vw",
                  whiteSpace: "nowrap",
                }}
              >
                창고료 조회
              </Typography>

              <Typography
                onClick={linkSpc}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Noto Sans KR",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  ml: "2vw",
                  whiteSpace: "nowrap",
                }}
              >
                특수 화물 사전 등록
              </Typography>

              <Typography
                onClick={linkFlight}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Noto Sans KR",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  ml: "2vw",
                  whiteSpace: "nowrap",
                }}
              >
                스케줄 조회
              </Typography>

              <Typography
                onClick={linkLocation}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Noto Sans KR",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  ml: "2vw",
                  whiteSpace: "nowrap",
                }}
              >
                AACT 위치
              </Typography>

              <Typography
                onClick={linkSites}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Noto Sans KR",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  ml: "2vw",
                  mr: "15vw",
                  whiteSpace: "nowrap",
                }}
              >
                관련 사이트
              </Typography>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
}
