import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
export default function CarrierSelectTablet({ props, carrierCode }) {
  const [data, setData] = useState([]);
  const [t1Data, setT1Data] = useState([]);
  const [t2Data, setT2Data] = useState([]);
  const [terminalFlag, setTerminalFlag] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const changeTerminal = (data) => {
    setTerminalFlag(data);
  };
  useEffect(() => {
    if (props.length > 0) {
      setData(props);
    }
  }, [props]);

  useEffect(() => {
    setT1Data(data.filter((item) => item.terminalCode === "T1"));
    setT2Data(data.filter((item) => item.terminalCode === "T2"));
  }, [data]);

  const gridSelect = (data) => {
    carrierCode(data);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "20%" }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              id="terminalButton"
              onClick={(e) => (e.stopPropagation(), changeTerminal("T1"))}
            >
              <ListItemText primary="T1" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              id="terminalButton"
              onClick={(e) => (e.stopPropagation(), changeTerminal("T2"))}
            >
              <ListItemText primary="T2" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemText primary="" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemText primary="" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemText primary="" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemText primary="" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box sx={{ width: "2%" }}>
        <Divider orientation="vertical" />
      </Box>
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor:
                    terminalFlag === "T1" ? "#FFFFFF90" : "#00000090",
                  border: 2,
                }}
              >
                <TableCell
                  sx={{
                    width: "5%",
                    fontSize: "10px",
                    color: terminalFlag === "T1" ? "#000000" : "#FFFFFF",
                  }}
                >
                  Carrier
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "10px",
                    color: terminalFlag === "T1" ? "#000000" : "#FFFFFF",
                  }}
                >
                  항공사명(영어)
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "10px",
                    color: terminalFlag === "T1" ? "#000000" : "#FFFFFF",
                  }}
                >
                  항공사명(한글)
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "10px",
                    color: terminalFlag === "T1" ? "#000000" : "#FFFFFF",
                  }}
                >
                  ICAO
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "10px",
                    color: terminalFlag === "T1" ? "#000000" : "#FFFFFF",
                  }}
                >
                  국가
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {terminalFlag === "T1" &&
                t1Data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      selected
                      onClick={(e) => (gridSelect(row), e.stopPropagation())}
                    >
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.carrierCode}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.engName}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.korName}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.icaoCode}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.nationalCode}
                      </TableCell>
                    </TableRow>
                  ))}

              {terminalFlag === "T2" &&
                t2Data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      selected
                      onClick={(e) => (gridSelect(row), e.stopPropagation())}
                    >
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.carrierCode}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.engName}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.korName}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.icaoCode}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>
                        {row.nationalCode}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={terminalFlag === "T1" ? t1Data.length : t2Data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
