import { Box, Button } from "@mui/joy";
import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import icon01 from "./images/화물추적.png";
import icon02 from "./images/창고료.png";
import icon03 from "./images/schedule.jpg";
import icon04 from "./images/locationicon.jpg";
import icon05 from "./images/sitesicon.jpg";
import icon06 from "./images/SpcImg.jpg";
import go from "./images/go.png";
import line from "./images/subMainLine.jpg";
import MainBackImg from "./images/MainBackImg.gif";
import "./css/MainCss.css";
import { useState } from "react";
import { useEffect } from "react";
import { shadow } from "pdfjs-dist";

export default function SubMain() {
  const history = useNavigate();
  const [change, setChange] = useState(true);
  const [isHoveredTrack, setIsHoveredTrack] = useState(false);
  const [isHoveredInquery, setIsHoveredInquery] = useState(false);
  const [isHoveredSpc, setIsHoveredSpc] = useState(false);
  const [isHoveredBoard, setIsHoveredBoard] = useState(false);
  const [isHoveredLocate, setIsHoveredLocate] = useState(false);
  const [isHoveredSites, setIsHoveredSites] = useState(false);
  const width = window.innerWidth;
  const TrackhandleMouseEnter = () => {
    setIsHoveredTrack(true);
  };

  const TrackhandleMouseLeave = () => {
    setIsHoveredTrack(false);
  };
  const InqueryhandleMouseEnter = () => {
    setIsHoveredInquery(true);
  };

  const InqueryhandleMouseLeave = () => {
    setIsHoveredInquery(false);
  };
  const SpchandleMouseEnter = () => {
    setIsHoveredSpc(true);
  };

  const SpchandleMouseLeave = () => {
    setIsHoveredSpc(false);
  };
  const BoardhandleMouseEnter = () => {
    setIsHoveredBoard(true);
  };

  const BoardhandleMouseLeave = () => {
    setIsHoveredBoard(false);
  };
  const LocatehandleMouseEnter = () => {
    setIsHoveredLocate(true);
  };

  const LocatehandleMouseLeave = () => {
    setIsHoveredLocate(false);
  };
  const SiteshandleMouseEnter = () => {
    setIsHoveredSites(true);
  };

  const SiteshandleMouseLeave = () => {
    setIsHoveredSites(false);
  };

  const searchTracking = () => {
    setChange(false);
    setTimeout(() => {
      history("/Tracking");
    }, 800);
  };

  const searchInquery = () => {
    setChange(false);
    setTimeout(() => {
      history("/Inquery");
    }, 800);
  };

  const searchSpc = () => {
    setChange(false);
    setTimeout(() => {
      history("/Spc");
    }, 800);
  };

  const searchFlight = () => {
    setChange(false);
    setTimeout(() => {
      history("/Flight");
    }, 800);
  };

  const searchLocation = () => {
    setChange(false);
    setTimeout(() => {
      history("/Location");
    }, 800);
  };

  const searchSites = () => {
    setChange(false);
    setTimeout(() => {
      history("/Sites");
    }, 800);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${MainBackImg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          zIndex: 0,
        }}
      ></Box>
      <Box sx={{ paddingTop: "25%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={change === true ? "slide-up" : "slide-down"}
        >
          <Paper
            elevation={3}
            sx={{
              width: isHoveredTrack ? 260 : 240,
              height: isHoveredTrack ? 300 : 280,
              marginRight: 9.5,
              textAlign: "center",
              transition: "width 1s,height 1s",
              borderRadius: 3,
            }}
            onMouseEnter={TrackhandleMouseEnter}
            onMouseLeave={TrackhandleMouseLeave}
            onClick={searchTracking}
          >
            <Card sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
              <CardMedia
                sx={{ height: "50%" }}
                image={icon01}
                title="green iguana"
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      marginRight: 10,
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                    }}
                  >
                    화물 추적
                  </p>
                  <img
                    src={go}
                    alt="Go Icon"
                    style={{ width: 28, cursor: "pointer" }}
                  />
                </div>
                <img src={line} />
              </CardContent>
            </Card>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              width: isHoveredInquery ? 260 : 240,
              height: isHoveredInquery ? 300 : 280,
              marginRight: 9.5,
              textAlign: "center",
              transition: "width 1s,height 1s",
              borderRadius: 3,
            }}
            onMouseEnter={InqueryhandleMouseEnter}
            onMouseLeave={InqueryhandleMouseLeave}
            onClick={searchInquery}
          >
            <Card sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
              <CardMedia
                sx={{ height: "50%" }}
                image={icon02}
                title="green iguana"
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      marginRight: 10,
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                    }}
                  >
                    창고료 조회
                  </p>
                  <img
                    src={go}
                    alt="Go Icon"
                    style={{ width: 28, cursor: "pointer" }}
                  />
                </div>
                <img src={line} />
              </CardContent>
            </Card>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              width: isHoveredSpc ? 260 : 240,
              height: isHoveredSpc ? 300 : 280,
              marginRight: 9.5,
              textAlign: "center",
              transition: "width 1s,height 1s",
              borderRadius: 3,
            }}
            onMouseEnter={SpchandleMouseEnter}
            onMouseLeave={SpchandleMouseLeave}
            onClick={searchSpc}
          >
            <Card sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
              <CardMedia
                sx={{ height: "50%" }}
                image={icon06}
                title="green iguana"
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      marginRight: 10,
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                    }}
                  >
                    특수 화물 사전 등록
                  </p>
                  <img
                    src={go}
                    alt="Go Icon"
                    style={{ width: 28, cursor: "pointer" }}
                  />
                </div>
                <img src={line} />
              </CardContent>
            </Card>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              width: isHoveredBoard ? 260 : 240,
              height: isHoveredBoard ? 300 : 280,
              marginRight: 9.5,
              textAlign: "center",
              transition: "width 1s,height 1s",
              borderRadius: 3,
            }}
            onMouseEnter={BoardhandleMouseEnter}
            onMouseLeave={BoardhandleMouseLeave}
            onClick={searchFlight}
          >
            <Card sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
              <CardMedia
                sx={{ height: "50%" }}
                image={icon03}
                title="green iguana"
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      marginRight: 10,
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                    }}
                  >
                    스케줄 조회
                  </p>
                  <img
                    src={go}
                    alt="Go Icon"
                    style={{ width: 28, cursor: "pointer" }}
                  />
                </div>
                <img src={line} />
              </CardContent>
            </Card>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              width: isHoveredSites ? 260 : 240,
              height: isHoveredSites ? 300 : 280,
              marginRight: 9.5,
              textAlign: "center",
              transition: "width 1s,height 1s",
              borderRadius: 3,
            }}
            onMouseEnter={SiteshandleMouseEnter}
            onMouseLeave={SiteshandleMouseLeave}
            onClick={searchSites}
          >
            <Card sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
              <CardMedia
                sx={{ height: "50%" }}
                image={icon05}
                title="green iguana"
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      marginRight: 10,
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                    }}
                  >
                    관련 사이트
                  </p>
                  <img
                    src={go}
                    alt="Go Icon"
                    style={{ width: 28, cursor: "pointer" }}
                  />
                </div>
                <img src={line} />
              </CardContent>
            </Card>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              width: isHoveredLocate ? 260 : 240,
              height: isHoveredLocate ? 300 : 280,
              textAlign: "center",
              marginRight: 3,
              transition: "width 1s,height 1s",
              borderRadius: 3,
            }}
            onMouseEnter={LocatehandleMouseEnter}
            onMouseLeave={LocatehandleMouseLeave}
            onClick={searchLocation}
          >
            <Card sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
              <CardMedia
                sx={{ height: "50%" }}
                image={icon04}
                title="green iguana"
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      marginRight: 10,
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                    }}
                  >
                    AACT 위치
                  </p>
                  <img
                    src={go}
                    alt="Go Icon"
                    style={{ width: 28, cursor: "pointer", marginTop: 2 }}
                  />
                </div>
                <img src={line} />
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
