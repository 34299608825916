import { Box, Button, Table, Typography } from "@mui/joy";
import {
  Autocomplete,
  FormControl,
  Input,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import InqueryBack from "../images/grey_img.jpg";
import "../css/TrackMobileCss.css";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalenderTimeMobile from "../util/CalenderTimeMobile";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { getRateCode, getRate, settleCal, calPdfDown } from "../API/InqueryAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4472C4BB",
    color: theme.palette.common.white,
    fontSize: "12px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    fontWeight: "bold",
  },
}));
export default function InqueryMobile() {
  const [cmbRateCode, setCmbRateCode] = useState([]);
  const [selectCmbRate, setSelectCmbRate] = useState(null);
  const [dateOutCust, setDateOutCust] = useState(false);
  const [txtOutDate, setTxtOutDate] = useState("");
  const [txtOutTime, setTxtOutTime] = useState("");
  const [dateInCust, setDateInCust] = useState(false);
  const [txtInDate, setTxtInDate] = useState("");
  const [txtInTime, setTxtInTime] = useState("");
  const [rateArray, setRateArray] = useState({});
  const [calArray, setCalArray] = useState([]);
  const [connoisseur, setConnoisseur] = useState("0");
  const [tariff, setTariff] = useState("0");
  const [weight, setWeight] = useState(0);
  const { setErrFlag, setLoadFlag, setCompleteCode } = useContext(AppContext);

  const popupStyle = {
    fontSize: "10px", // 원하는 폰트 크기로 설정
  };

  const handleCmbRateChange = (event, newValue) => {
    setSelectCmbRate(newValue);
  };
  const dateOutCustClick = () => {
    setTxtOutDate("");
    setTxtOutTime("");
    setDateOutCust(true);
  };
  const dateInCustClick = () => {
    setTxtInDate("");
    setTxtInTime("");
    setDateInCust(true);
  };
  const connoisseurChange = (event) => {
    let price = event.target.value;
    price = Number(price.replaceAll(",", ""));
    if (isNaN(price)) {
      setConnoisseur(0);
    } else {
      setConnoisseur(price.toLocaleString("ko-KR"));
    }
  };
  const tariffChange = (event) => {
    let price = event.target.value;
    price = Number(price.replaceAll(",", ""));
    if (isNaN(price)) {
      setTariff(0);
    } else {
      setTariff(price.toLocaleString("ko-KR"));
    }
  };
  const weightChange = (event) => {
    let price = event.target.value;
    price = Number(price.replaceAll(",", ""));
    if (isNaN(price)) {
      setWeight(0);
    } else {
      setWeight(price.toLocaleString("ko-KR"));
    }
  };

  const changeInTxtDate = (props) => {
    if (props.date !== null && props.date !== undefined) {
      setTxtInDate(props.date);
    }
    if (props.time !== null && props.time !== undefined) {
      setTxtInTime(props.time);
    }
    if (props.close === false) {
      if (txtInTime == null || txtInTime == undefined || txtInTime == "") {
        setErrFlag({ code: "Y", data: "시간을 확인하세요" });
      } else {
        setErrFlag({ code: "N", data: "시간을 확인하세요" });
        setTimeout(() => {
          setDateInCust(false);
        }, 500);
      }
    }
  };
  const changeOutTxtDate = (props) => {
    if (props.date !== null && props.date !== undefined) {
      setTxtOutDate(props.date);
    }
    if (props.time !== null && props.time !== undefined) {
      setTxtOutTime(props.time);
    }
    if (props.close === false) {
      if (txtOutTime == null || txtOutTime == undefined || txtOutTime == "") {
        setErrFlag({ code: "Y", data: "시간을 확인하세요" });
      } else {
        setErrFlag({ code: "N", data: "시간을 확인하세요" });
        setTimeout(() => {
          setDateOutCust(false);
        }, 500);
      }
    }
  };
  useEffect(() => {
    getRateCode()
      .then((result) => result && setCmbRateCode(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);
  useEffect(() => {
    setCalArray([]);

    var flag = { cmbFlag: false, inFlag: false, outFlag: false };
    if (selectCmbRate === null) {
    } else {
      flag.cmbFlag = true;
    }
    if (txtInTime === null || txtInTime === undefined || txtInTime === "") {
    } else {
      flag.inFlag = true;
    }
    if (txtOutTime === null || txtOutTime === undefined || txtOutTime === "") {
    } else {
      flag.outFlag = true;
    }

    if (
      flag.cmbFlag === false ||
      flag.inFlag === false ||
      flag.outFlag === false
    ) {
      if (flag.cmbFlag === false) {
        setErrFlag({ code: "Y", data: "요금유형을 확인하세요" });
      } else if (flag.inFlag === false) {
        setErrFlag({ code: "Y", data: "반입일을 확인하세요" });
      } else if (flag.outFlag === false) {
        setErrFlag({ code: "Y", data: "출고일을 확인하세요" });
      }
      setRateArray({});
    } else {
      setErrFlag({ code: "N", data: "" });
      if (txtOutDate < txtInDate) {
        setErrFlag({ code: "Y", data: "반입일/출고일 확인" });
      } else {
        const inDate =
          moment(txtInDate).format("YYYYMMDD") +
          moment(txtInTime).format("HHmmss");
        const outDate =
          moment(txtOutDate).format("YYYYMMDD") +
          moment(txtOutTime).format("HHmmss");
        const cmbCode = selectCmbRate.codeCode;
        getRate(inDate, outDate, cmbCode, setErrFlag, setLoadFlag)
          .then((result) => result && setRateArray(result))
          .catch((error) => setErrFlag({ code: "Y", data: error.message }));
      }
    }
  }, [selectCmbRate, txtInTime, txtOutTime]);
  const calcCargoClick = () => {
    setErrFlag({ code: "N", data: "" });

    if (connoisseur.length > 0) {
    } else {
      setErrFlag({ code: "Y", data: "감정가를 확인하세요" });
      return;
    }

    if (tariff.length > 0) {
    } else {
      setErrFlag({ code: "Y", data: "관세를 확인하세요" });
      return;
    }
    if (weight.length > 0) {
    } else {
      setErrFlag({ code: "Y", data: "Weight를 확인하세요" });
      return;
    }

    if (Object.keys(rateArray).length > 0) {
      const inputData = {
        rateType: rateArray.rateType,
        unitAmount:
          Number(connoisseur.replaceAll(",", "")) +
          Number(tariff.replaceAll(",", "")),
        cargeableWeight: Number(weight.replaceAll(",", "")),
        cargoInTime:
          moment(txtInDate).format("YYYYMMDD") +
          moment(txtInTime).format("HHmm"),
        cargoOutTime:
          moment(txtOutDate).format("YYYYMMDD") +
          moment(txtOutTime).format("HHmm"),
        storageDays: rateArray.storageDays,
        storageHours: rateArray.storageHours,
        rateDto: rateArray,
      };
      settleCal(inputData, setErrFlag, setLoadFlag)
        .then((result) => result && setCalArray(result))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    } else {
      setErrFlag({ code: "Y", data: "반입일, 출고일, 요금유형을 확인하세요" });
    }
  };

  const pdfDown = async () => {
    let arrayBuffer = null;
    if (Object.keys(calArray).length > 0) {
      const data = {
        cargoInDate:
          moment(txtInDate).format("YYYY-MM-DD") +
          " " +
          moment(txtInTime).format("HH:mm"),
        cargoOutDate:
          moment(txtOutDate).format("YYYY-MM-DD") +
          " " +
          moment(txtOutTime).format("HH:mm"),
        connoisseur: connoisseur,
        tariff: tariff,
        weight: weight,
        whcTotal: calArray[0].whcTotal,
        thcTotal: calArray[0].thcTotal,
        allTotal: Number(calArray[0].thcTotal) + Number(calArray[0].whcTotal),
        whcaCal: (
          ((Number(rateArray.whcaBaseRate) +
            Number(rateArray.whcaAddRate) * Number(rateArray.storageDays)) *
            (Number(connoisseur.replaceAll(",", "")) +
              Number(tariff.replaceAll(",", "")))) /
          1000
        ).toFixed(2),
        whcwCal: (
          (Number(rateArray.whcwBaseRate) +
            Number(rateArray.whcwAddRate) * Number(rateArray.storageDays)) *
          Number(weight.replaceAll(",", ""))
        ).toFixed(2),
        rateDto: rateArray,
      };

      calPdfDown(data, setErrFlag, setLoadFlag)
        .then((result) => {
          if (result) {
            arrayBuffer = result;
          }
        })
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));

      const blob = new Blob([arrayBuffer], {
        type: "application/pdf;charset=UTF-8",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download =
        "CargoCalc_" + moment(new Date()).format("YYYYMMDD_HHmmsss"); //matches[1].replace(/['"]/g, ""); // 파일명 지정
      link.click();
    } else {
      setErrFlag({ code: "Y", data: "창고료 계산이 안됬습니다." });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${InqueryBack})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", // 이미지 반복 없음
        minHeight: "inherit",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "20%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Box sx={{ display: "flex", marginLeft: "1.5%", marginRight: "1.5%" }}>
          <Paper
            elevation={3}
            sx={{
              backgroundColor: "#DAE3F3BB",
              width: "40%",
              borderRadius: 8,
            }}
          >
            <Typography
              sx={{
                paddingTop: "6.5%",
                marginRight: "1%",
                marginLeft: "6.5%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
              level="h6"
              variant="plain"
            >
              창고료 조회
            </Typography>
          </Paper>
          <Box sx={{ paddingTop: 1, marginRight: 0, marginLeft: "auto" }}>
            <Button
              size="sm"
              color="success"
              sx={{
                color: "white",
                borderColor: "white",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
              variant="outlined"
              onClick={calcCargoClick}
            >
              계산하기
            </Button>

            <Button
              size="sm"
              color="neutral"
              variant="outlined"
              sx={{
                marginLeft: 1,
                color: "white",
                borderColor: "white",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
              onClick={pdfDown}
            >
              다운로드
            </Button>
          </Box>
        </Box>
        <Paper
          sx={{
            margin: "1.7%",
            padding: "1.5%",
            backgroundColor: "rgba(255, 255, 255, 70%)",
          }}
          elevation={3}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <table className="inputTableMobileInquery">
              <tr>
                <th>반입일</th>
                <td>
                  <TextField
                    id="input-with-icon-textfield"
                    label=""
                    variant="standard"
                    size="small"
                    value={
                      txtInDate +
                      " " +
                      (txtInTime && moment(txtInTime).format("HH:mm"))
                    }
                    onClick={(e) => dateInCustClick()}
                    sx={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthIcon />
                        </InputAdornment>
                      ),
                      style: { fontSize: "10px" },
                      disabled: true,
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <th>감정가&nbsp;(&nbsp;원&nbsp;)</th>
                <td>
                  <FormControl fullWidth size="small" variant="standard">
                    <Input
                      id="standard-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      inputProps={{ style: { fontSize: "12px" } }}
                      value={connoisseur && connoisseur}
                      onChange={connoisseurChange}
                    />
                  </FormControl>
                </td>
              </tr>
              <tr>
                <th>Weight&nbsp;(&nbsp;Kg&nbsp;)</th>
                <td>
                  {" "}
                  <TextField
                    id="standard-start-adornment"
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">kg</InputAdornment>
                      ),
                      style: { fontSize: "12px" },
                    }}
                    value={weight && weight}
                    onChange={weightChange}
                    variant="standard"
                  />
                </td>
              </tr>
            </table>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <table className="inputTableMobileInquery2">
              <tr>
                <th>출고일</th>
                <td>
                  {" "}
                  <TextField
                    id="input-with-icon-textfield"
                    label=""
                    variant="standard"
                    size="small"
                    value={
                      txtOutDate +
                      " " +
                      (txtOutTime && moment(txtOutTime).format("HH:mm"))
                    }
                    onClick={dateOutCustClick}
                    sx={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthIcon />
                        </InputAdornment>
                      ),
                      style: { fontSize: "10px" },
                      disabled: true,
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <th>관세&nbsp;(&nbsp;원&nbsp;)</th>
                <td>
                  {" "}
                  <FormControl fullWidth size="small" variant="standard">
                    <Input
                      id="standard-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      inputProps={{ style: { fontSize: "12px" } }}
                      value={tariff && tariff}
                      onChange={tariffChange}
                    />
                  </FormControl>
                </td>
              </tr>
              <tr>
                <th>요금유형</th>
                <td>
                  {" "}
                  <Autocomplete
                    id="clear-on-escape"
                    options={cmbRateCode}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          style: { fontSize: "12px" }, // 텍스트 크기를 조정합니다.
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.codeName}
                    getOptionKey={(option) => option.codeCode}
                    value={selectCmbRate}
                    onChange={handleCmbRateChange}
                    PaperComponent={(props) => (
                      <Paper {...props} style={popupStyle} />
                    )}
                  />
                </td>
              </tr>
            </table>
          </Box>
        </Paper>
        <Paper
          sx={{ display: "flex", backgroundColor: "rgba(255, 255, 255, 0%)" }}
          elevation={3}
        >
          <Box
            sx={{
              margin: "1.5%",
              backgroundColor: "rgba(255, 255, 255, 70%)",
              width: "50%",
              marginRight: "auto",
              marginLeft: "1.5%",
              display: dateInCust === true ? "block" : "none",
            }}
          >
            <CalenderTimeMobile props={changeInTxtDate} />
          </Box>
          <Box
            sx={{
              margin: "1.5%",
              backgroundColor: "rgba(255, 255, 255, 70%)",
              width: "50%",
              marginRight: "1.5%",
              marginLeft: "auto",
              display: dateOutCust === true ? "block" : "none",
            }}
          >
            <CalenderTimeMobile props={changeOutTxtDate} />
          </Box>
        </Paper>
        <Paper
          elevation={3}
          sx={{ margin: "1.5%", backgroundColor: "rgba(255, 255, 255, 70%)" }}
        >
          <Table borderAxis="bothBetween">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>종가율</StyledTableCell>
                <StyledTableCell>종량율</StyledTableCell>
                <StyledTableCell>T.H.C</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell>기본료</StyledTableCell>
                <StyledTableCell>{rateArray.whcaBaseAmount}</StyledTableCell>
                <StyledTableCell>{rateArray.whcwBaseAmount}</StyledTableCell>
                <StyledTableCell>{rateArray.thcBaseAmount}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>기본 요율</StyledTableCell>
                <StyledTableCell>{rateArray.whcaBaseRate}</StyledTableCell>
                <StyledTableCell>{rateArray.whcwBaseRate}</StyledTableCell>
                <StyledTableCell>{rateArray.thcBaseRate}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>1일 할증</StyledTableCell>
                <StyledTableCell>{rateArray.whcaAddRate}</StyledTableCell>
                <StyledTableCell>{rateArray.whcwAddRate}</StyledTableCell>
                <StyledTableCell>{rateArray.thcAddRate}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper
          sx={{
            margin: "1.5%",
            padding: "1.5%",
            backgroundColor: "rgba(255, 255, 255, 70%)",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <table className="inputTableMobileInquery">
              <tr>
                <th>보관료</th>
                <td>{calArray.length > 0 && calArray[0].whcTotal}</td>
              </tr>
            </table>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <table className="inputTableMobileInquery">
              <tr>
                <th>T.H.C</th>
                <td>{calArray.length > 0 && calArray[0].thcTotal}</td>
              </tr>
            </table>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <table className="inputTableMobileInquery3">
              <tr>
                <th>창고료 &nbsp;(&nbsp; VAT별도&nbsp;)</th>
                <td>{calArray.length > 0 && calArray[0].total}</td>
              </tr>
            </table>
          </Box>
        </Paper>
        <Paper
          elevation={3}
          sx={{ margin: "1.5%", backgroundColor: "#D6DCE5BB" }}
        >
          <Typography
            level="h6"
            variant="plain"
            component="h6"
            sx={{
              padding: "1.5%",
              marginRight: 0,
              marginLeft: "auto",
              fontSize: "9px",
            }}
          >
            ※ 상기 예상 창고료는 입출고 시간 및 요금유형에 따라 실제 금액과 상이
            할 수 있습니다. ※
          </Typography>
          <Typography level="h6" sx={{ color: "black", padding: "1%" }}>
            Details
          </Typography>
        </Paper>
        <Paper elevation={3} sx={{ margin: "1.5%" }}>
          <Box sx={{ width: "100%" }}>
            <Table color="neutral" variant="soft" sx={{ display: "flex" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 300, fontSize: "8px" }}>
                    반입일시
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>총과세</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>보관일수</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>종가료</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>
                    보관료 = 종가료 + 종량료
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>
                    창고료 ( 창고료 = 보관료 + T.H.C )
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: 350 }}>
                    {" "}
                    {txtInTime &&
                      txtInDate &&
                      moment(txtInDate).format("YYYY-MM-DD") +
                        " " +
                        moment(txtInTime).format("HH:mm")}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    {connoisseur && Number(connoisseur.replaceAll(",", ""))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{rateArray && rateArray.storageDays}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    {rateArray &&
                      calArray.length > 0 &&
                      (
                        ((Number(rateArray.whcaBaseRate) +
                          Number(rateArray.whcaAddRate) *
                            Number(rateArray.storageDays)) *
                          (Number(connoisseur.replaceAll(",", "")) +
                            Number(tariff.replaceAll(",", "")))) /
                        1000
                      ).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {calArray.length > 0 && calArray[0].whcTotal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    {calArray.length > 0 && calArray[0].total}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table color="neutral" variant="soft" sx={{ display: "flex" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 300, fontSize: "8px" }}>
                    반출일시
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>관세</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>총 중량</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>종량료</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontSize: "8px" }}>T.H.C</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: 350 }}>
                    {txtOutTime &&
                      txtOutDate &&
                      moment(txtOutDate).format("YYYY-MM-DD") +
                        " " +
                        moment(txtOutTime).format("HH:mm")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    {tariff && Number(tariff.replaceAll(",", ""))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    {weight && Number(weight.replaceAll(",", ""))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {rateArray &&
                      calArray.length > 0 &&
                      (
                        (Number(rateArray.whcwBaseRate) +
                          Number(rateArray.whcwAddRate) *
                            Number(rateArray.storageDays)) *
                        Number(weight.replaceAll(",", ""))
                      ).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    {calArray.length > 0 && calArray[0].thcTotal}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
