import { Box, Button } from "@mui/joy";
import React from "react";
import Calendar from "react-calendar";
import "./css/CalenderCust.css";
import moment from "moment/moment";
import { useState } from "react";

export default function CalenderCust({ props }) {
  const [selectData, setSelectData] = useState(null);
  const clickCalendar = (data) => {
    setSelectData(data);
    props(data);
  };
  return (
    <Box>
      <Calendar
        value={selectData}
        onChange={clickCalendar}
        formatDay={(locale, date) => moment(date).format("DD")}
      />
    </Box>
  );
}
