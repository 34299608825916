import {
  Box,
  Chip,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAirCombo, getMawbInfo } from "../API/SpcAPI";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import SpcTitleTablet from "./SpcTitleTablet";
import SpcDetailTablet from "./SpcDetailTablet";
import SpcFileTablet from "./SpcFileTablet";
import { setSpcSaveClient, setSpcSaveDelete } from "../API/SpcAPI";
import { Typography } from "@mui/joy";
import { CssTextField } from "../util/CovMui";

export default function SpcMawbInfoTablet() {
  const { spcFlag, spcData } = useParams();
  const { setErrFlag, setLoadFlag, saveFlag, setSaveFlag, setDeleteFlag } =
    useContext(AppContext);
  const navigator = useNavigate();
  const [data, setData] = useState({});
  const [carrierList, setCarrierList] = useState([]);
  const [airPhoneNum, setAirPhoneNum] = useState();
  const [fileArray, setFileArray] = useState([]);
  const [originFile, setOriginFile] = useState([]);

  useEffect(() => {
    getAirCombo(setErrFlag, setLoadFlag)
      .then((result) => result && setCarrierList(result))
      .catch((error) => console.log("error" + error.message));
  }, []);

  useEffect(() => {
    if (spcFlag === "I") {
      setData({
        agencySid: null,
        agencyFlag: "W",
        airSid: null,
        fltDate: "",
        mawb: spcData,
        clientName: "",
        phone: "",
        email: "",
        saveFlag: spcFlag,
      });
    }
    if (spcFlag === "U") {
      getMawbInfo(spcData, setErrFlag, setLoadFlag)
        .then(
          (result) =>
            result &&
            (setData({
              agencySid: result[0].mainEntities.agencySid,
              agencyFlag: result[0].mainEntities.agencyFlag,
              airSid: result[0].mainEntities.airSid,
              fltDate: result[0].mainEntities.agencyDate,
              mawb: spcData,
              clientName: result[0].mainEntities.agencyClientName,
              phone: result[0].mainEntities.agencyPhone,
              email: result[0].mainEntities.agencyEmail,
              agencyRemark: result[0].mainEntities.agencyRemark,
              saveFlag: spcFlag,
            }),
            setOriginFile(
              result[0].subEntities.map((item) => ({
                id: item.id,
                agencyFileSid: item.agencyFileSid,
                airSid: item.airSid,
                fileExtension: item.fileExtension,
                fileName: item.fileName,
                spcType: item.spcType,
                file: null,
                flag: "N",
              }))
            ))
        )
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [spcFlag, spcData, saveFlag]);

  useEffect(() => {
    const tempPhone = carrierList.find(
      (item) => item.guid === data.airSid
    )?.airPhone;

    setAirPhoneNum(tempPhone);
  }, [data, carrierList]);

  const delClick = () => {
    setSpcSaveDelete(data.agencySid, setErrFlag, setLoadFlag)
      .then((result) => result && (navigator("/Spc"), setDeleteFlag(true)))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };
  const closePage = () => {
    navigator("/Spc");
  };

  const changeData = (newValue, fieldName) => {
    setData((prevData) => ({ ...prevData, [fieldName]: newValue }));
  };

  const onClickSave = (event) => {
    event.preventDefault();
    const formData = new FormData();
    const tempData = fileArray.map((item) => ({
      type: item.type,
      originFileList: item.originFileList,
    }));
    let count = 0;
    tempData.forEach((item) => {
      if (
        Array.from(item.originFileList).filter((item) => item.flag !== "D")
          .length > 0
      ) {
        count++;
      }
    });
    if (originFile && count === 0) {
      setErrFlag({ code: "Y", data: "서류를 확인해 주세요." });
      return;
    }
    if (Object.values(data).some((value) => value === "")) {
      setErrFlag({ code: "Y", data: "모든 정보를 입력해주세요" });
      return;
    }
    setErrFlag({ code: "Y", data: fileArray.length });
    let delArray = [];
    for (const codeValue in fileArray) {
      if (fileArray.hasOwnProperty(codeValue)) {
        const fileIndex = fileArray[codeValue];

        if (fileIndex.originFileList.length > 0) {
          const tempFileArray = fileIndex.originFileList;
          Array.from(tempFileArray).forEach((item) => {
            if (item.flag === "I") {
              formData.append("files", item.file);
              formData.append("code", fileIndex.type);
            } else if (item.flag === "D" && item.agencyFileSid !== null) {
              delArray.push({
                fileGuid: item.agencyFileSid,
                uploadStatus: "Y",
              });
            }
          });
        }
      }
    }

    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            spcSid: data.agencySid,
            mawb: data.mawb.replaceAll("-", ""),
            fltDate: data.fltDate,
            airSid: data.airSid,
            clientName: data.clientName,
            phone: data.phone.replaceAll("-", ""),
            email: data.email,
            saveFlag: data.saveFlag,
            fileList: delArray,
          }),
        ],
        { type: "application/json" }
      )
    );
    setSpcSaveClient(formData, setErrFlag, setLoadFlag)
      .then((result) => result && (setSaveFlag(true), navigator("/Spc")))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const recvFileData = (value) => {
    setFileArray(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F7F8",
        minHeight: "inherit",
      }}
    >
      <Box
        sx={{
          width: "90%",
          paddingTop: "20%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Box>
          <SpcTitleTablet
            agencyFlag={data.agencyFlag}
            saveFlag={spcFlag}
            onClickClose={closePage}
            saveClick={onClickSave}
            deleteClick={delClick}
          />
        </Box>
        <Box>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1.5,
              backgroundColor: "#F5F7F8",
            }}
          >
            <TableContainer sx={{ width: "70%" }}>
              <Table className="tableGroup1-tablet">
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Noto Sans KR",
                        fontWeight: "bold",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      MAWB
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          pt: 1,
                          pb: 1,
                          pl: 2,
                          pr: 2,
                          width: "fit-content",
                          borderRadius: 2,
                          fontFamily: "Noto Sans KR",
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        {data?.mawb}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {data?.agencyFlag === "W" && (
              <Chip label="Awaiting" color="primary" variant="outlined" />
            )}
            {data?.agencyFlag === "Y" && (
              <Chip label="Confirmed" color="success" />
            )}
            {data?.agencyFlag === "N" && (
              <Chip label="Rejected" color="warning" />
            )}
          </Paper>
        </Box>
        <Box>
          <SpcDetailTablet
            originData={data}
            convertData={changeData}
            carrierList={carrierList}
            airPhoneNum={airPhoneNum}
          />
        </Box>
        <Box>
          <SpcFileTablet
            originData={data}
            originFile={originFile}
            sendFileData={recvFileData}
          />
        </Box>
        {data?.agencyFlag === "N" && (
          <Box sx={{ mt: 3, mb: 2 }}>
            <Paper
              elevation={0}
              sx={{
                mb: 2,
                backgroundColor: "#00114F20",
                display: "flex",
                alignItems: "center",
                pt: 0.3,
                pb: 0.3,
              }}
            >
              <ArrowRightIcon fontSize="large" sx={{ marginLeft: "1.7%" }} />
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontFamily: "Noto Sans KR",
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                REJECT
              </Typography>
            </Paper>
            <Paper
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0%)",
              }}
              elevation={0}
            >
              <Box sx={{ textAlign: "center" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CssTextField
                    sx={{ backgroundColor: "#FFF" }}
                    multiline
                    rows={2}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={data?.agencyRemark}
                  />
                </FormControl>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
}
