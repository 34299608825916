import React, { useState } from "react";
import Main from "./Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { getTokenData } from "./API/AppAPI";
import SpcMawbInfo from "./SpcMawbInfo";

function App() {
  const firstPageLoad = async () => {
    await getTokenData();
  };

  useEffect(() => {
    firstPageLoad();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Tracking" element={<Main />} />
          <Route path="/Inquery" element={<Main />} />
          <Route path="/Flight" element={<Main />} />
          <Route path="/Location" element={<Main />} />
          <Route path="/Sites" element={<Main />} />
          <Route path="/Spc" element={<Main />} />
          <Route path="/SpcInfo/:spcFlag/:spcData" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
