import { Box, Button, Table, Typography } from "@mui/joy";
import { Paper } from "@mui/material";
import React from "react";
import SitesMobileBack from "../images/grey_img.jpg";
import Site1 from "../images/site3.png";
import Site2 from "../images/site1.png";
import Site3 from "../images/site2.png";
import Site4 from "../images/site4.png";
import Site5 from "../images/site5.png";

export default function SitesTablet() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: `url(${SitesMobileBack})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", // 이미지 반복 없음
        minHeight: "inherit",
      }}
    >
      <Box
        sx={{
          width: "90%",
          paddingTop: "20%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Box sx={{ display: "flex", marginLeft: "1.5%", marginRight: "1.5%" }}>
          <Paper
            elevation={3}
            sx={{
              backgroundColor: "#DAE3F3BB",
              width: "60%",
              borderRadius: 8,
              marginBottom: "1vw",
              marginTop: "1vw",
            }}
          >
            <Typography
              sx={{
                marginRight: "1%",
                marginLeft: "1.7%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontSize: "2.8vw",
                padding: "0.8vw",
              }}
              level="h4"
              variant="plain"
            >
              관련 사이트
            </Typography>
          </Paper>
        </Box>
        <Paper
          elevation={3}
          sx={{
            paddingTop: "3vw",
            paddingBottom: "3vw",
            margin: "1.5%",
            backgroundColor: "#D6DCE5BB",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Site1 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              paddingTop: "1vw",
              paddingBottom: "1vw",
              marginBottom: "1.5vw",
              backgroundColor: "white",
              border: "solid 1.8px #CCC",
              borderRadius: "5px",
            }}
          >
            <a
              href="http://service.aact.co.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Site1}
                alt="Site 1"
                style={{ width: "18vw", verticalAlign: "middle" }}
              />
            </a>
          </Box>
          {/* Site2 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              paddingTop: "1vw",
              paddingBottom: "1vw",
              marginBottom: "1.5vw",
              backgroundColor: "white",
              border: "solid 1.8px #CCC",
              borderRadius: "5px",
            }}
          >
            <a
              href="https://www.sharp.co.kr/kr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Site4}
                alt="Site 4"
                style={{ width: "18vw", verticalAlign: "middle" }}
              />
            </a>
          </Box>
          {/* Site3 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              paddingTop: "1vw",
              paddingBottom: "1vw",
              marginBottom: "1.5vw",
              backgroundColor: "white",
              border: "solid 1.8px #CCC",
              borderRadius: "5px",
            }}
          >
            <a
              href="https://www.ulogishub.com/index.jsp?sso=ok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Site2}
                alt="Site 2"
                style={{ width: "18vw", verticalAlign: "middle" }}
              />
            </a>
          </Box>
          {/* Site4 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              paddingTop: "1vw",
              paddingBottom: "1vw",
              marginBottom: "1.5vw",
              backgroundColor: "white",
              border: "solid 1.8px #CCC",
              borderRadius: "5px",
            }}
          >
            <a
              href="https://unipass.customs.go.kr/csp/index.do"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Site3}
                alt="Site 3"
                style={{ width: "18vw", verticalAlign: "middle" }}
              />
            </a>
          </Box>
          {/* Site5 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              paddingTop: "1vw",
              paddingBottom: "1vw",
              backgroundColor: "white",
              border: "solid 1.8px #CCC",
              borderRadius: "5px",
            }}
          >
            <a
              href="https://www.seabaycargo.com/air-tracking/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Site5}
                alt="Site 5"
                style={{ width: "22vw", verticalAlign: "middle" }}
              />
            </a>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
