import { Snackbar, Alert } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function FailMobile({ props }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  useEffect(() => {
    if (props.code === "Y") {
      setOpen(true);
      setData(props.data);
    } else {
      setOpen(false);
      setData(props.data);
    }
  }, [props]);

  const closeBtn = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={closeBtn}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        variant="filled"
        severity="error"
        onClose={closeBtn}
        sx={{ width: "100%" }}
      >
        {data}
      </Alert>
    </Snackbar>
  );
}
