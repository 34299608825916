import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Divider, TextField, Typography } from "@mui/material";
import StepOn01 from "./images/화물접수1.png";
import StepOff01 from "./images/화물접수2.png";
import StepOn02 from "./images/화물입고1.png";
import StepOff02 from "./images/화물입고2.png";
import StepOn05 from "./images/화물출고1.png";
import StepOff05 from "./images/화물출고2.png";
import StepOn03 from "./images/서면분류1.png";
import StepOff03 from "./images/서면분류2.png";
import StepOn00 from "./images/보류1.png";
import StepOff00 from "./images/보류2.png";

export default function TrackingStep({ props }) {
  const steps = ["서면분류", "보류", "검수", "입고", "출고"];
  const findHoldGetTime = (array) => {
    let ret = "";
    if (array) {
      let tempRet = "";
      tempRet = array.statusLog.find((item) => item.cargoStatusName === "보류");
      if (tempRet) {
        ret = tempRet.cargoStatusTime.substring(5);
      } else {
        ret = "";
      }
    } else {
      ret = "";
    }

    return ret;
  };
  const findFirstGetTime = (array) => {
    let ret = "";
    if (array) {
      let tempRet = "";
      tempRet = array.statusLog.find(
        (item) => item.cargoStatusName === "서면 분류"
      );
      if (tempRet) {
        ret = tempRet.cargoStatusTime.substring(5);
      } else {
        ret = "";
      }
    } else {
      ret = "";
    }

    return ret;
  };

  const findSecGetTime = (array) => {
    let ret = "";
    if (array) {
      let tempRet = "";
      const tempHold = array.status;
      if (tempHold === "I03") {
        return ret;
      }

      tempRet = array.statusLog.find((item) => item.cargoStatusName === "검수");
      if (tempRet) {
        ret = tempRet.cargoStatusTime.substring(5);
      } else {
        ret = "";
      }
    } else {
      ret = "";
    }

    return ret;
  };
  const findTirGetTime = (array) => {
    let ret = "";
    if (array) {
      let tempRet = "";
      const tempHold = array.status;
      if (tempHold === "I03") {
        return ret;
      }
      tempRet = array.statusLog.find((item) => item.cargoStatusName === "입고");
      if (tempRet) {
        ret = tempRet.cargoStatusTime.substring(5);
      } else {
        ret = "";
      }
    } else {
      ret = "";
    }

    return ret;
  };
  const findLastGetTime = (array) => {
    let ret = "";
    if (array) {
      let tempRet = "";
      const tempHold = array.status;
      if (tempHold === "I03") {
        return ret;
      }
      tempRet = array.statusLog.find(
        (item) => item.cargoStatusName === "출고 완료"
      );
      if (tempRet) {
        ret = tempRet.cargoStatusTime.substring(5);
      } else {
        ret = "";
      }
    } else {
      ret = "";
    }

    return ret;
  };
  const trackImage = () => {
    return (
      <Box sx={{ height: 150, display: "flex", marginBottom: "1%" }}>
        <Divider
          sx={{
            backgroundColor: "#00498C",
            marginLeft: "8.5%",
            marginRight: "1%",
          }}
          orientation="vertical"
        />
        <Box sx={{ display: "block", width: "20%" }}>
          <img
            src={props && props.status === "I01" ? StepOn03 : StepOff03}
            width="60%"
            style={{ marginTop: "20%" }}
          />
          <Typography
            width={"100%"}
            minHeight={"35%"}
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: "17px",
              marginTop: "3%",
              marginLeft: "10%",
            }}
            variant="h6"
          >
            {findFirstGetTime(props)}
          </Typography>
        </Box>
        <Divider
          sx={{
            backgroundColor: "#00498C",
            marginRight: "1%",
          }}
          orientation="vertical"
        />
        <Box sx={{ display: "block", width: "20%" }}>
          <img
            src={props && props.status === "I03" ? StepOn00 : StepOff00}
            width="60%"
            style={{ marginTop: "20%" }}
          />
          <Typography
            width={"100%"}
            minHeight={"35%"}
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: "17px",
              marginTop: "3%",
              marginLeft: "10%",
            }}
            variant="h6"
          >
            {findHoldGetTime(props)}
          </Typography>
        </Box>
        <Divider
          sx={{
            backgroundColor: "#00498C",
            marginRight: "1%",
          }}
          orientation="vertical"
        />
        <Box sx={{ display: "block", width: "20%" }}>
          <img
            src={props && props.status === "I02" ? StepOn01 : StepOff01}
            width="60%"
            style={{ marginTop: "20%" }}
          />
          <Typography
            width={"100%"}
            minHeight={"35%"}
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: "17px",
              marginTop: "3%",
              marginLeft: "10%",
            }}
            variant="h6"
          >
            {findSecGetTime(props)}
          </Typography>
        </Box>
        <Divider
          sx={{
            backgroundColor: "#00498C",
            marginRight: "1%",
          }}
          orientation="vertical"
        />
        <Box sx={{ display: "block", width: "20%" }}>
          <img
            src={props && props.status === "I04" ? StepOn02 : StepOff02}
            width="60%"
            style={{ marginTop: "20%" }}
          />
          <Typography
            width={"100%"}
            minHeight={"35%"}
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: "17px",
              marginTop: "3%",
              marginLeft: "10%",
            }}
            variant="h6"
          >
            {findTirGetTime(props)}
          </Typography>
        </Box>

        <Divider
          sx={{
            backgroundColor: "#00498C",
            marginRight: "1%",
          }}
          orientation="vertical"
        />
        <Box sx={{ display: "block", width: "20%" }}>
          <img
            src={props && props.status === "I06" ? StepOn05 : StepOff05}
            width="60%"
            style={{ marginTop: "20%" }}
          />
          <Typography
            width={"100%"}
            minHeight={"35%"}
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: "17px",
              marginTop: "3%",
              marginLeft: "10%",
            }}
            variant="h6"
          >
            {findLastGetTime(props)}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box sx={{ padding: "1%" }}>
      {props && trackImage()}
      <Box>
        <Stepper
          sx={{ width: "91%" }}
          activeStep={
            props && props.status === "I01"
              ? 0
              : props.status === "I03"
              ? 1
              : props.status === "I02"
              ? 2
              : props.status === "I04"
              ? 3
              : props.status === "I06"
              ? 4
              : 0
          }
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
}
