const apiUrl = process.env.REACT_APP_APIEAWB;

export const getRateCode = async () => {
  try {
    const response = await fetch(`${apiUrl}/eawb/cmbRate`, {
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        const errorText = await response.text();
        throw new Error(errorText);
      } else {
        throw new Error(`${response.statusText}`);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      const data = res.map((row, index) => ({
        id: index,
        codeCode: row.codeMaster.codeCode,
        codeName: row.objectLanguage.onjectName,
        value1Char: row.codeMaster.value1Char,
      }));
      return data;
    }
  } catch (Error) {}
  return false;
};
export const getRate = async (
  inDate,
  outDate,
  cmbCode,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/eawb/rate?inDate=${inDate}&outDate=${outDate}&rate=${cmbCode}`,
      {
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      }
    );
    if (!response.ok) {
      throw new Error(`${response.statusText}`);
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "No Data" });
    } else {
      setLoadFlag(false);
      return res;
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "요율계산에러 : " + error });
  }
  setLoadFlag(false);
  return false;
};

export const settleCal = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/eawb/settleCal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`${response.statusText}`);
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "No Data" });
    } else {
      if (res.error === "Y") {
        throw new Error(res.data[0]);
      } else {
        const dataArray = res.data.map((row) => ({
          thcTotal: row.thcTotal,
          whcTotal: row.whcTotal,
          total: Number(row.thcTotal) + Number(row.whcTotal),
        }));
        setLoadFlag(false);
        return dataArray;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "계산에러 : " + error });
  }
  setLoadFlag(false);
  return false;
};

export const calPdfDown = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);

  try {
    const response = await fetch(`${apiUrl}/eawb/getInquery`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`${response.statusText}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    setLoadFlag(false);
    return arrayBuffer;
  } catch (error) {
    setErrFlag({ code: "Y", data: "다운에러 : " + error });
  }

  setLoadFlag(false);
  return false;
};
