import { Box, Button, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { SaveButton, CancelButtonTablet } from "../util/CovMui";

export default function SpcTitleTablet({
  agencyFlag,
  saveFlag,
  onClickClose,
  saveClick,
  deleteClick,
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#00114F",
          width: "50%",
          borderRadius: 8,
          display: "flex",
          alignItems: "center",
          pt: 1,
          pb: 1,
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontFamily: "Noto Sans KR",
            fontWeight: "500",
            fontSize: "1.2rem",
            ml: 3,
          }}
        >
          화물 등록
        </Typography>
      </Paper>
      <Box>
        {agencyFlag === "Y" ? (
          <CancelButtonTablet variant="outlined" onClick={onClickClose}>
            닫기
          </CancelButtonTablet>
        ) : (
          <>
            {saveFlag === "U" ? (
              <>
                <SaveButton
                  onClick={saveClick}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  저장
                </SaveButton>
                <SaveButton
                  onClick={deleteClick}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  삭제
                </SaveButton>
              </>
            ) : (
              <SaveButton
                onClick={saveClick}
                variant="contained"
                sx={{ mr: 1 }}
              >
                저장
              </SaveButton>
            )}
            <CancelButtonTablet variant="contained" onClick={onClickClose}>
              닫기
            </CancelButtonTablet>
          </>
        )}
      </Box>
    </Box>
  );
}
