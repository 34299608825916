import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";

export default function ViewFile({ url, handleClose, dimension }) {
  const width = (dimension && dimension.width) || "60vw";
  const height = (dimension && dimension.height) || "95vh";
  const [scale, setScale] = useState(1);
  const handleWheel = (event) => {
    event.preventDefault();

    // event.deltaY는 마우스 휠의 스크롤 방향과 크기를 나타냄
    if (event.deltaY < 0) {
      // 스크롤을 위로 할 때(이미지 확대)
      setScale((prevScale) => Math.min(prevScale + 0.3, 2.5)); // 최대 3배까지 확대
    } else {
      // 스크롤을 아래로 할 때(이미지 축소)
      setScale((prevScale) => Math.max(prevScale - 0.3, 1)); // 최소 원본 크기까지 축소
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: width,
          height: height,
          backgroundColor: "#323639",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "4%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            sx={{
              zIndex: 9999,
              mr: 1,
              mt: 0.5,
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: "#FFF" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: "96%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {String(url.type).toUpperCase() === "PDF" ? (
            <iframe
              src={url && url.data}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              title="pdf"
              id="tempfrm"
            />
          ) : (
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <img
                src={url.data}
                style={{
                  display: "block",
                  maxWidth: width,
                  maxHeight: `calc(${height} - (${height} * 0.04))`,
                  objectFit: "contain",
                  transform: `scale(${scale})`,
                  transition: "transform 0.2s ease",
                }}
                onWheel={handleWheel}
                alt="img"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
