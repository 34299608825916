const apiUrl = process.env.REACT_APP_APIEAWB;

export const getCarrierCode = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/eawb/getCarrier`, {
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        const errorText = await response.text();
        throw new Error(errorText);
      } else {
        throw new Error(`${response.statusText}`);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "No Data" });
    } else {
      const data = res.map((row, index) => ({
        id: index,
        carrierCode: row.carrierCode,
        engName: row.engName,
        korName: row.korName,
        icaoCode: row.icaoCode,
        nationalCode: row.nationalCode,
        terminalCode: row.terminalCode,
      }));
      setLoadFlag(false);
      return data;
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "서버실행에러 : " + error });
  }
  setLoadFlag(false);
  return false;
};

export const getSch = async (
  inoutFlag,
  fltDate,
  fatchCarrierData,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/eawb/getsch?inoutFlag=${inoutFlag}&fltDate=${fltDate}&carrierCode=${fatchCarrierData}`,
      {
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        const errorText = await response.text();
        throw new Error(errorText);
      } else {
        throw new Error(`${response.statusText}`);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "No Data" });
    } else {
      const data = res.map((row, index) => ({
        id: index,
        scheduleSid: row.schMasterEntity.scheduleSid,
        flightDate: row.schMasterEntity.flightDate,
        inoutFlag: row.schMasterEntity.inoutFlag,
        standartTime:
          ("0" + new Date(row.schMasterEntity.estimatedTime).getHours()).slice(
            -2
          ) +
          ":" +
          (
            "0" + new Date(row.schMasterEntity.estimatedTime).getMinutes()
          ).slice(-2),
        estimatedTime:
          ("0" + new Date(row.schMasterEntity.estimatedTime).getHours()).slice(
            -2
          ) +
          ":" +
          (
            "0" + new Date(row.schMasterEntity.estimatedTime).getMinutes()
          ).slice(-2),
        actualTime:
          ("0" + new Date(row.schMasterEntity.actualTime).getHours()).slice(
            -2
          ) +
          ":" +
          ("0" + new Date(row.schMasterEntity.actualTime).getMinutes()).slice(
            -2
          ),
        flightNo: row.schMasterEntity.flightNo,
        originCode: row.schMasterEntity.originCode,
        destCode: row.schMasterEntity.destCode,
        cargoCount: row.cargoCount,
        statusName: row.statusName,
        processRatio: row.processRatio,
      }));
      setLoadFlag(false);
      return data;
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "서버실행에러 : " + error });
  }
  setLoadFlag(false);
  return false;
};
