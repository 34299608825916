import { Box, Button, LinearProgress, Typography } from "@mui/joy";
import React from "react";

import Table from "@mui/joy/Table";
import { Paper, TableContainer, TextField } from "@mui/material";
import { useState } from "react";

export default function Board({ props }) {
  const [size, setSize] = React.useState("sm");
  const [txtSearch, setTxtSearch] = useState("");
  const filteredData = props.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value !== undefined &&
        value.toString().toLowerCase().includes(txtSearch.toLowerCase())
    )
  );
  return (
    <Box sx={{ padding: "3%", backgroundColor: "#006633AA" }}>
      <Paper elevation={3} sx={{ width: "30%", border: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            marginBottom: "2%",
          }}
        >
          <Box sx={{ marginRight: "2%" }}>
            <Typography
              sx={{
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontSize: "16px",
                fontWeight: "bold",
                paddingTop: "60%",
                width: "100%",
              }}
            >
              조 회
            </Typography>
          </Box>
          <Box>
            <TextField
              id="standard-basic"
              label="* 연관검색어 입력"
              variant="standard"
              onChange={(e) => setTxtSearch(e.target.value)}
            />
          </Box>
        </Box>
      </Paper>
      <Paper
        elevation={16}
        sx={{ padding: 2, height: 630, marginTop: 2, border: 2 }}
      >
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table aria-label="table sizes" size={size} borderAxis="x">
            <thead>
              <tr>
                <th>날짜</th>
                <th>편명</th>
                <th>도착지</th>
                <th>상태</th>
                <th>화물</th>
                <th>진행률</th>
                <th>STA</th>
                <th>ETA</th>
                <th>ATA</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row.id}>
                  <td>{row.flightDate}</td>
                  <td>{row.flightNo}</td>
                  <td>
                    {row.inoutFlag === "I" ? row.originCode : row.destCode}
                  </td>
                  <td>{row.statusName}</td>
                  <td>{row.cargoCount}</td>
                  <td>
                    <LinearProgress
                      determinate
                      variant="outlined"
                      color="neutral"
                      size="sm"
                      thickness={30}
                      value={row.processRatio}
                      sx={{
                        "--LinearProgress-radius": "0px",
                        "--LinearProgress-progressThickness": "24px",
                        boxShadow: "sm",
                        borderColor: "neutral.500",
                        width: 80,
                      }}
                    >
                      <Typography
                        level="body-xs"
                        fontWeight="xl"
                        textColor="common.white"
                        sx={{ mixBlendMode: "difference" }}
                      >
                        {`${Math.round(row.processRatio)}%`}
                      </Typography>
                    </LinearProgress>
                  </td>
                  <td>{row.standartTime}</td>
                  <td>{row.estimatedTime}</td>
                  <td>{row.actualTime}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
