import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useState, useRef } from "react";
import CalenderCust from "../CalenderCust";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import TableCombo from "../util/TableCombo";
import { CssTextField, CssInput } from "../util/CovMui";

export default function SpcDetailMobile({
  originData,
  convertData,
  carrierList,
  airPhoneNum,
}) {
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [fltCalendar, setFltCalendar] = useState(false);
  const [txtError, setTxtError] = useState({
    carrierFlag: false,
    fltDateFlag: false,
    agencyNameFlag: false,
    phoneFlag: false,
    emailFlag: false,
  });
  const [airPhone, setAirPhone] = useState();
  const calendarRef = useRef(false);
  const [airClicked, setAirClicked] = useState(false);
  const comboTableRef = useRef();

  useEffect(() => {
    setAirPhone(airPhoneNum);
  }, [airPhoneNum]);

  useEffect(() => {
    setTxtError({
      carrierFlag: originData.airSid ? false : true,
      fltDateFlag:
        originData.fltDate === "" || originData.fltDate === undefined,
      agencyNameFlag:
        originData.clientName === "" || originData.clientName === undefined,
      phoneFlag: originData.phone === "" || originData.phone === undefined,
      emailFlag: originData.email === "" || originData.email === undefined,
    });
  }, [originData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setFltCalendar(false);
      }
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setAirClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validateForm = (fieldName, value) => {
    if (!value || value === "") {
      setTxtError((prevState) => ({
        ...prevState,
        [fieldName]: true,
      }));
    } else {
      setTxtError((prevState) => ({
        ...prevState,
        [fieldName]: false,
      }));
    }
  };
  const changeFltDate = (newDate) => {
    handleInputChange(moment(newDate).format("YYYY-MM-DD"), "fltDate");
    validateForm("fltDateFlag", moment(newDate).format("YYYY-MM-DD"));
    setFltCalendar(false);
  };
  const onClickFltDate = (e) => {
    e.stopPropagation();
    setFltCalendar((prev) => !prev);
  };
  const phoneValid = (value) => {
    let ret = value.replace(/\D/g, "").replace("-", "");
    if (ret.startsWith("010")) {
      ret = value.replace(/\D/g, "").replace("-", "").slice(0, 11);
      if (ret.length <= 3) return ret;
      if (ret.length <= 7) return ret.replace(/^(\d{3})(\d{1,4})$/, "$1-$2");
      return ret.replace(/^(\d{3})(\d{4})(\d{1,4})$/, "$1-$2-$3");
    } else {
      ret = value.replace(/\D/g, "").replace("-", "").slice(0, 10);
      if (ret.startsWith("02")) {
        // 서울 번호 포맷팅
        if (ret.length <= 2) return ret;
        if (ret.length <= 6) return ret.replace(/^(\d{2})(\d{1,4})$/, "$1-$2");
        return ret.replace(/^(\d{2})(\d{4})(\d{1,4})$/, "$1-$2-$3");
      } else if (ret.startsWith("0")) {
        // 핸드폰 및 일반 번호 포맷팅
        if (ret.length <= 3) return ret;
        if (ret.length <= 6) return ret.replace(/^(\d{3})(\d{1,3})$/, "$1-$2");
        return ret.replace(/^(\d{3})(\d{3})(\d{1,4})$/, "$1-$2-$3");
      }
    }
  };

  const handleInputChange = (newValue, fieldName) => {
    if (fieldName === "mawb") {
      newValue = newValue.replace(/[^0-9]/g, "").slice(0, 11);
      newValue = newValue.replace(/^(\d{3})(\d{4})(\d+)$/, `$1-$2-$3`);
    }

    if (fieldName === "phone") {
      newValue = newValue.replace(/\D/g, "");
      newValue = phoneValid(newValue)
        ? phoneValid(newValue)
        : newValue.slice(0, 10);
      if (newValue.startsWith("0") || newValue === "") {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
    }
    if (fieldName === "mawb" || fieldName === "fltDate") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }
    if (fieldName === "airSid") {
      if (newValue) {
        const tempPhone = carrierList.filter(
          (item) => item.guid === newValue
        )[0]?.airPhone;
        setAirPhone(tempPhone);
      } else {
        setAirPhone("");
      }
      setAirClicked(false);
    }
    convertData(newValue, fieldName);
  };

  const airTableOpen = () => {
    setAirClicked((prev) => !prev);
  };

  const textClear = (fieldName) => {
    if (originData.agencyFlag === "Y") {
      return;
    }
    convertData("", fieldName);
  };
  return (
    <Box>
      <Typography
        sx={{
          textAlign: "right",
          fontSize: "0.8rem",
          fontFamily: "Noto Sans KR",
          fontWeight: 500,
          mt: 3,
          mb: 1,
        }}
      >
        {airPhone && "항공사 연락처 : " + airPhone}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#00114F20",
          display: "flex",
          alignItems: "center",
          pt: 0.2,
          pb: 0.2,
        }}
      >
        <ArrowRightIcon fontSize="large" sx={{ marginLeft: "1.7%" }} />
        <Typography
          sx={{
            fontSize: "1.1rem",
            fontFamily: "Noto Sans KR",
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
        >
          세부 정보
        </Typography>
      </Paper>

      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          pt: 2,
          backgroundColor: "rgba(255, 255, 255, 0%)",
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box ref={comboTableRef}>
            <CssInput
              sx={{ backgroundColor: "#fff" }}
              fullWidth
              size="small"
              value={String(
                originData.airSid
                  ? carrierList.find((item) => item.guid === originData.airSid)
                      ?.value2 || ""
                  : ""
              )}
              onClick={(event) => {
                airTableOpen();
                event.stopPropagation();
              }}
              error={txtError.carrierFlag}
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "1.05rem",
                    }}
                  >
                    항공사
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={(event) => {
                      airTableOpen();
                      event.stopPropagation();
                    }}
                  >
                    {airClicked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </IconButton>
                </InputAdornment>
              }
              readOnly
            />
            <Box>
              {airClicked && originData.agencyFlag !== "Y" && (
                <TableCombo
                  tableList={carrierList}
                  changeData={(value) => {
                    handleInputChange(value, "airSid");
                    validateForm("carrierFlag", value);
                  }}
                  type={"CRRCD"}
                  width={comboTableRef && comboTableRef.current.offsetWidth}
                />
              )}
            </Box>
          </Box>
          <CssTextField
            sx={{ backgroundColor: "#fff", mt: 1 }}
            fullWidth
            size="small"
            value={String(
              originData.airSid
                ? carrierList.find((item) => item.guid === originData.airSid)
                    ?.terminalCode || ""
                : ""
            )}
            required
            error={txtError.carrierFlag}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: "#000000DD",
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "1.05rem",
                    }}
                  >
                    터미널
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={{ visibility: "hidden" }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: true,
            }}
          />
          <CssTextField
            sx={{ backgroundColor: "#fff", mt: 1 }}
            fullWidth
            size="small"
            value={originData.clientName}
            required
            error={txtError.agencyNameFlag}
            onChange={(e) => {
              handleInputChange(e.target.value, "clientName");
              validateForm("agencyNameFlag", e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: "#000000DD",
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "1.05rem",
                    }}
                  >
                    대리점
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      textClear("clientName");
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: originData.agencyFlag === "Y" ? true : false,
            }}
          />
          <Box ref={calendarRef} sx={{ position: "relative" }}>
            <CssTextField
              sx={{ backgroundColor: "#fff", mt: 1 }}
              fullWidth
              autoComplete="off"
              size="small"
              error={txtError.fltDateFlag}
              value={
                originData.fltDate !== ""
                  ? moment(originData.fltDate).format("YYYY-MM-DD")
                  : ""
              }
              required
              onChange={(e) => {
                handleInputChange(e.target.value, "fltDate");
                validateForm("fltDateFlag", e.target.value);
              }}
              onClick={(e) =>
                originData.agencyFlag === "Y" ? "" : onClickFltDate(e)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      sx={{
                        color: "#000000DD",
                        fontFamily: "Noto Sans KR",
                        fontWeight: 500,
                        fontSize: "1.05rem",
                      }}
                    >
                      날짜
                    </Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarMonthIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            ></CssTextField>
            {fltCalendar && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 9999,
                  top: "100%",
                  width: calendarRef && calendarRef.current.offsetWidth,
                }}
              >
                <CalenderCust props={(date) => changeFltDate(date)} />
              </Box>
            )}
          </Box>
          <CssTextField
            sx={{ mt: 1, backgroundColor: "#fff" }}
            fullWidth
            size="small"
            error={txtError.phoneFlag || phoneError}
            value={String(originData?.phone).replace(
              /^(\d{2,3})(\d{3,4})(\d{4})$/,
              `$1-$2-$3`
            )}
            required
            onChange={(e) => {
              handleInputChange(e.target.value, "phone");
              validateForm("phoneFlag", e.target.value);
            }}
            helperText={phoneError ? "유효하지 않은 전화번호입니다." : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: "#000000DD",
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "1.05rem",
                    }}
                  >
                    연락처
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      textClear("phone");
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: originData.agencyFlag === "Y" ? true : false,
            }}
          />
          <CssTextField
            sx={{ mt: 1, backgroundColor: "#fff" }}
            fullWidth
            size="small"
            error={txtError.emailFlag || emailError}
            value={originData.email}
            required
            onChange={(e) => {
              handleInputChange(e.target.value, "email");
              validateForm("emailFlag", e.target.value);
            }}
            helperText={emailError ? "유효하지 않은 이메일 형식입니다." : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: "#000000DD",
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "1.05rem",
                    }}
                  >
                    이메일
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      textClear("email");
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: originData.agencyFlag === "Y" ? true : false,
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
}
