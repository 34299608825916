import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepOn01 from "../images/화물접수1.png";
import StepOff01 from "../images/화물접수2.png";
import StepOn02 from "../images/화물입고1.png";
import StepOff02 from "../images/화물입고2.png";
import StepOn05 from "../images/화물출고1.png";
import StepOff05 from "../images/화물출고2.png";
import StepOn03 from "../images/서면분류1.png";
import StepOff03 from "../images/서면분류2.png";
import StepOn00 from "../images/보류1.png";
import StepOff00 from "../images/보류2.png";

export default function TrackingStepMobile({ props }) {
  const steps = ["검수", "보류", "입고", "서면분류", "출고"];

  return (
    <Box sx={{ padding: "0.1%" }}>
      <Box>
        <Stepper
          sx={{ width: "100%", marginTop: "5%" }}
          activeStep={
            props && props.status === "I01"
              ? 0
              : props.status === "I03"
              ? 1
              : props.status === "I02"
              ? 2
              : props.status === "I04"
              ? 3
              : props.status === "I06"
              ? 4
              : 0
          }
          alternativeLabel
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>
                <img
                  src={
                    index === 0
                      ? props && props.status === "I01"
                        ? StepOn03
                        : StepOff03
                      : index === 1
                      ? props && props.status === "I03"
                        ? StepOn00
                        : StepOff00
                      : index === 2
                      ? props && props.status === "I02"
                        ? StepOn01
                        : StepOff01
                      : index === 3
                      ? props && props.status === "I04"
                        ? StepOn02
                        : StepOff02
                      : index === 4
                      ? props && props.status === "I06"
                        ? StepOn05
                        : StepOff05
                      : 0
                  }
                  width={"100%"}
                />
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
}
