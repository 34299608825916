import { Box, Paper, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./css/TrackSearchCss.css";

export default function TrackBlSearchModal({ searchArray, blMdRes }) {
  const [dataArray, setDataArray] = useState([]);
  const [txtSearch, setTxtSearch] = useState("");
  useEffect(() => {
    const array = searchArray.map((row) => ({
      id: row.id,
      cargoSid: row.cargoSid,
      masterAirWayBillNo: row.masterAirWayBillNo,
      houseAirWayBillNo: row.houseAirWayBillNo,
    }));
    setDataArray(array);
  }, [searchArray]);
  const columns = [
    {
      field: "id",
      headerName: "순번",
      flex: 0.2,
      headerClassName: "headerColor",
    },
    {
      field: "masterAirWayBillNo",
      headerName: "MAWB",
      flex: 1,
      headerClassName: "headerColor",
    },
    {
      field: "houseAirWayBillNo",
      headerName: "HAWB",
      flex: 1,
      headerClassName: "headerColor",
    },
  ];

  const dataSelectChange = (selection) => {
    const data = filteredData.find((item) => item.id === selection[0]);
    blMdRes(data);
  };

  const filteredData = dataArray.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value !== undefined &&
        value.toString().toLowerCase().includes(txtSearch.toLowerCase())
    )
  );

  return (
    <Paper elevation={3} sx={{ width: "100%", borderRadius: "10px" }}>
      <Box>
        <TextField
          id="standard-basic"
          label="* 연관검색어 입력"
          variant="standard"
          onChange={(e) => setTxtSearch(e.target.value)}
          sx={{ marginLeft: "5%", marginBottom: "2%" }}
        />
      </Box>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredData}
          rowHeight={50}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          onRowSelectionModelChange={dataSelectChange}
        />
      </Box>
    </Paper>
  );
}
