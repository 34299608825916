import { Box, Typography } from "@mui/joy";
import { Paper } from "@mui/material";
import React from "react";
import Site1 from "./images/site1.png";
import Site2 from "./images/site2.png";
import Site3 from "./images/site3.png";
import Site4 from "./images/site4.png";
import Site5 from "./images/site5.png";
import "./css/TrackCss.css";

export default function Sites() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "90vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            paddingTop: "10%",
            paddingLeft: "15%",
            paddingRight: "15%",
          }}
        >
          <Box
            sx={{ display: "flex", marginLeft: "1.5%", marginRight: "1.5%" }}
          >
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#DAE3F3BB",
                width: "60%",
                borderRadius: 8,
                marginBottom: "0.5vw",
              }}
            >
              <Typography
                sx={{
                  marginRight: "1%",
                  marginLeft: "1.7%",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontSize: "1.1vw",
                  padding: "0.8vw",
                }}
                level="h4"
                variant="plain"
              >
                관련 사이트
              </Typography>
            </Paper>
          </Box>
          <Paper
            elevation={3}
            sx={{
              padding: "1.5vw 2vw",
              margin: "1.5%",
              backgroundColor: "#D6DCE5BB",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {/* Site1 */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                  paddingTop: "0.6vw",
                  paddingBottom: "0.6vw",
                  backgroundColor: "white",
                  marginRight: "1.5vw",
                  border: "solid 1.8px #CCC",
                  borderRadius: "5px",
                }}
              >
                <a
                  href="http://service.aact.co.kr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Site3} alt="Site 3" style={{ width: "8.5vw" }} />
                </a>
              </Box>
              <Box sx={{ width: "2px", backgroundColor: "#868e96" }} />
              {/* Site2 */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                  paddingTop: "0.6vw",
                  paddingBottom: "0.6vw",
                  backgroundColor: "white",
                  marginLeft: "1.5vw",
                  marginRight: "1.5vw",
                  border: "solid 1.8px #CCC",
                  borderRadius: "5px",
                }}
              >
                <a
                  href="https://www.sharp.co.kr/kr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Site4} alt="Site 4" style={{ width: "8.5vw" }} />
                </a>
              </Box>
              <Box sx={{ width: "2px", backgroundColor: "#868e96" }} />
              {/* Site3 */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                  paddingTop: "0.6vw",
                  paddingBottom: "0.6vw",
                  backgroundColor: "white",
                  marginLeft: "1.5vw",
                  marginRight: "1.5vw",
                  border: "solid 1.8px #CCC",
                  borderRadius: "5px",
                }}
              >
                <a
                  href="https://www.ulogishub.com/index.jsp?sso=ok"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Site1} alt="Site 1" style={{ width: "8.5vw" }} />
                </a>
              </Box>
              <Box sx={{ width: "2px", backgroundColor: "#868e96" }} />
              {/* Site4 */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                  paddingTop: "0.6vw",
                  paddingBottom: "0.6vw",
                  backgroundColor: "white",
                  marginLeft: "1.5vw",
                  border: "solid 1.8px #CCC",
                  borderRadius: "5px",
                }}
              >
                <a
                  href="https://unipass.customs.go.kr/csp/index.do"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Site2} alt="Site 2" style={{ width: "8.5vw" }} />
                </a>
              </Box>
            </Box>
            <Box sx={{ display: "flex", mt: 3, width: "24%" }}>
              {/* Site5 */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                  paddingTop: "0.5vw",
                  paddingBottom: "0.5vw",
                  backgroundColor: "white",
                  marginRight: "1.5vw",
                  border: "solid 1.8px #CCC",
                  borderRadius: "5px",
                }}
              >
                <a
                  href="https://www.seabaycargo.com/air-tracking/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Site5} alt="Site 5" style={{ width: "9vw" }} />
                </a>
              </Box>
              {/* <Box sx={{ width: "2px", backgroundColor: "#868e96" }} /> */}
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
}
