import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import SquareIcon from "@mui/icons-material/Square";
import { getFileInfo, setFileDelete } from "./API/SpcAPI";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import "./css/Spc.css";
import ViewFile from "./util/ViewFile";
import { useEffect } from "react";
import { useRef } from "react";
import { UploadButton } from "./util/CovMui";

export default function SpcFileInfo({ data, sendFile, forwardedRef }) {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [url, setUrl] = useState();
  const fileInputRef = useRef(null);
  useEffect(() => {
    if (forwardedRef && forwardedRef.current) {
      forwardedRef.current.focus();
    }
  }, [forwardedRef]);
  const handleUploadClick = () => {
    if (data[0]) {
      document.getElementById(`fileInput-${data[0].type}`).click();
    }
  };
  const fileAddClick = (event, value, flag, value2) => {
    event.preventDefault();
    if (flag === "I") {
      const newFiles = event.target.files;

      let sumSize = 0;

      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];
      for (let i = 0; i < newFiles.length; i++) {
        sumSize += newFiles[i].size;
        if (!allowedTypes.includes(newFiles[i].type)) {
          setErrFlag({ code: "Y", data: "파일 타입 확인" });
          return;
        }
      }

      if (newFiles.length > 0) {
        sendFile({ flag: flag, type: value, files: newFiles });
      } else {
        setErrFlag({ code: "Y", data: "파일길이없음" });
      }
    }
    if (flag === "D") {
      sendFile({
        type: value,
        files: value2,
        flag: flag,
      });
    }
    if (flag === "R") {
      sendFile({ type: value, files: value2, flag: flag });
    }
    event.target.value = null;
  };

  const clickFile = (index, fileExtension) => {
    const tempData = data[0]?.originFileList.find((item) => item.id === index);
    if (tempData) {
      if (tempData.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setUrl({
            type: tempData.fileExtension,
            data: e.target.result,
          });
        };
        reader.readAsDataURL(tempData.file);
      }
      if (tempData.agencyFileSid) {
        getFileInfo(tempData.agencyFileSid, setErrFlag, setLoadFlag)
          .then(
            (result) => result && setUrl({ type: fileExtension, data: result })
          )
          .catch((error) => setErrFlag({ code: "Y", data: error.message }));
      }
    } else {
      setErrFlag({ code: "Y", data: "파일없음" });
    }
  };
  const handleClose = () => {
    setUrl(null);
  };
  return (
    <Paper
      ref={forwardedRef}
      sx={{
        position: "relative",
        pt: 3,
        pb: 3,
        pl: 5,
        pr: 5,
        mt: 3,
        mb: 3,
        minHeight: "20vh",
      }}
      elevation={0}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SquareIcon sx={{ fontSize: 8, mr: 1 }} />
        <Typography
          sx={{
            fontFamily: "Noto Sans KR",
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          구비 서류
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        {data &&
          data.map((item) => (
            <Typography
              component="span"
              sx={{
                display: "inline",
                fontFamily: "Noto Sans KR",
              }}
            >
              {item.docList}
            </Typography>
          ))}
        <Box>
          <input
            type="file"
            id={`fileInput-${data && data[0]?.type}`}
            onChange={(e) => fileAddClick(e, data[0].type, "I", "")}
            style={{ display: "none" }}
            multiple
            accept=".jpg, .jpeg, .png, .pdf"
            required
            ref={fileInputRef}
          />
          <UploadButton
            onClick={(e) => (e.stopPropagation(), handleUploadClick())}
            endIcon={<UploadIcon />}
            variant="outlined"
          >
            파일 추가
          </UploadButton>
        </Box>
      </Box>
      {/* 파일 Insert부분 */}
      {data[0].originFileList && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mt: 2,
            columnGap: 2,
            maxHeight: "80%",
            overflowX: "auto",
          }}
        >
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table className="file-table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "15%" }}>No</TableCell>
                  <TableCell sx={{ width: "70%" }}>파일명</TableCell>
                  <TableCell sx={{ width: "15%" }}>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data[0]?.originFileList?.map((item) => (
                    <TableRow>
                      <TableCell>{item.id + 1}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            cursor: "pointer",
                            textDecorationLine:
                              item.flag === "D" ? "line-through" : "none",
                            "&:hover": {
                              color: "#46699C",
                            },
                          }}
                          onClick={(e) => (
                            e.stopPropagation(),
                            clickFile(item.id, item.fileExtension)
                          )}
                        >
                          {item.fileName && item.fileName}
                        </Box>
                      </TableCell>
                      {item.flag !== "D" ? (
                        <TableCell>
                          <IconButton>
                            <DeleteIcon
                              onClick={(e) => (
                                e.stopPropagation(),
                                fileAddClick(e, data[0].type, "D", item.id)
                              )}
                              fontSize="small"
                            />
                          </IconButton>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <IconButton
                            onClick={(e) => (
                              e.stopPropagation(),
                              fileAddClick(e, data[0].type, "R", item.id)
                            )}
                          >
                            <ReplayIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {url && (
            <Modal open={url ? true : false} onClose={handleClose}>
              <ViewFile url={url} handleClose={handleClose} />
            </Modal>
          )}
        </Box>
      )}
    </Paper>
  );
}
