import React from "react";
import { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { getCommonList } from "../API/SpcAPI";
import table from "../css/TableCombo.module.css";

//사용부분
// eslint-disable-next-line no-lone-blocks
{
  /* <Box
  sx={{
    justifyContent: "center",
  }}
>
  <Input
    className="admin-input"
    value={row.userPriority && row.userPriority}
    onClick={(event) => {
      deptTableOpen(index);
      event.stopPropagation();
    }}
    endAdornment={
      <InputAdornment position="end">
        <IconButton
          onClick={(event) => {
            deptTableOpen(index);
            event.stopPropagation();
          }}
        >
          {deptClicked[index] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </InputAdornment>
    }
  />
  <Box
    ref={deptClicked[index] ? comboTableRef : null}
    sx={{ marginLeft: "10%" }}
  >
    {deptClicked[index] && (
      <TableCombo
        tableList={dptCode}                                                       -- 테이블리스트
        changeData={(value) => changeData(value, "userPriority", index)}          -- selectedCode로 change할 함수
        type={"DEPT"}                                                             -- 공통 등록에서 불러올 지정 타입
      />
    )}
  </Box>
</Box>; */
}

export default function TableCombo({ tableList, changeData, type, width }) {
  const [selectedCode, setSelectedCode] = useState();
  const [col, setCol] = useState({});
  const { setErrFlag, setLoadFlag } = useContext(AppContext);

  useEffect(() => {
    getCommonList(type, setErrFlag, setLoadFlag)
      .then((result) => result && setCol(result[0]))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, [type, setErrFlag, setLoadFlag]);

  const handleRowClick = (code) => {
    setSelectedCode(code);
  };

  return (
    <Paper
      sx={{
        position: "absolute",
        p: 0.5,
        zIndex: 9999,
        width: width ? width : "12%",
      }}
    >
      <TableContainer
        sx={{
          borderRadius: 1,
          maxHeight: "35vh",
          overflowX: "auto",
        }}
      >
        <Table className={table.comboTable}>
          <TableHead
            sx={{
              backgroundColor: "rgba(0, 17, 79, 0.52)",
            }}
          >
            <TableRow>
              {col.value1Char !== null && String(col.value1Char).length > 0 && (
                <TableCell sx={{ width: "3%" }}>{col.value1Char}</TableCell>
              )}
              {col.value2Char !== null && String(col.value2Char).length > 0 && (
                <TableCell>{col.value2Char}</TableCell>
              )}
              {col.value3Char !== null && String(col.value3Char).length > 0 && (
                <TableCell>{col.value3Char}</TableCell>
              )}

              {col.value4Char !== null && String(col.value4Char).length > 0 && (
                <TableCell>{col.value4Char}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableList &&
              tableList.map((item) => (
                <TableRow
                  hover
                  key={item.guid}
                  onClick={(e) => {
                    changeData(item.guid);
                    handleRowClick(item["value" + col.value4Char]);
                    e.stopPropagation();
                  }}
                >
                  {col.value1Char !== null &&
                    String(col.value1Char).length > 0 && (
                      <TableCell>{item.value1}</TableCell>
                    )}
                  {col.value2Char !== null &&
                    String(col.value2Char).length > 0 && (
                      <TableCell>{item.value2}</TableCell>
                    )}
                  {col.value3Char !== null &&
                    String(col.value3Char).length > 0 && (
                      <TableCell>{item.value3}</TableCell>
                    )}
                  {col.value4Char !== null &&
                    String(col.value4Char).length > 0 && (
                      <TableCell>{item.value4}</TableCell>
                    )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
