import { Box, Button, LinearProgress, Typography } from "@mui/joy";
import React from "react";

import Table from "@mui/joy/Table";
import { Paper, TableContainer, TextField } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function BoardTablet({ props, props2 }) {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const [size, setSize] = React.useState("sm");
  const [txtSearch, setTxtSearch] = useState("");
  const filteredData = props.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value !== undefined &&
        value.toString().toLowerCase().includes(txtSearch.toLowerCase())
    )
  );
  const closeClick = () => {
    props2(false);
  };

  return (
    <Box sx={{ padding: "3%", backgroundColor: "#006633AA" }}>
      <Box sx={{ width: "100%", display: "flex" }}>
        <Paper elevation={3} sx={{ width: "50%", border: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              marginBottom: "2%",
            }}
          >
            <Box sx={{ marginRight: "2%" }}>
              <Typography
                sx={{
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontSize: "14px",
                  fontWeight: "bold",
                  paddingTop: "70%",
                  width: "100%",
                }}
              >
                조 회
              </Typography>
            </Box>
            <Box>
              <TextField
                id="standard-basic"
                label="* 연관검색어 입력"
                variant="standard"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // 원하는 폰트 크기로 변경
                }}
                onChange={(e) => setTxtSearch(e.target.value)}
              />
            </Box>
          </Box>
        </Paper>

        <Box
          sx={{ marginRight: 0, marginLeft: "auto", height: "50%" }}
          onClick={closeClick}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Paper
        elevation={16}
        sx={{
          padding: 1,
          height: height < width ? 300 : 600,
          marginTop: 1,
          border: 2,
        }}
      >
        <TableContainer sx={{ maxHeight: height < width ? 300 : 600 }}>
          <Table
            sx={{ textAlign: "start" }}
            aria-label="table sizes"
            size={size}
            borderAxis="x"
          >
            <thead>
              <tr>
                <th>날짜</th>
                <th>편명</th>
                <th>도착지</th>
                <th>상태</th>
                <th>화물</th>
                <th>진행률</th>
                <th>STA</th>
                <th>ETA</th>
                <th>ATA</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row.id}>
                  <td>{row.flightDate}</td>
                  <td>{row.flightNo}</td>
                  <td>
                    {row.inoutFlag === "I" ? row.originCode : row.destCode}
                  </td>
                  <td>{row.statusName}</td>
                  <td>{row.cargoCount}</td>
                  <td>
                    <LinearProgress
                      determinate
                      variant="outlined"
                      color="neutral"
                      size="sm"
                      thickness={30}
                      value={row.processRatio}
                      sx={{
                        "--LinearProgress-radius": "0px",
                        "--LinearProgress-progressThickness": "24px",
                        boxShadow: "sm",
                        borderColor: "neutral.500",
                      }}
                    >
                      <Typography
                        level="body-xs"
                        fontWeight="xl"
                        textColor="common.white"
                        sx={{ mixBlendMode: "difference" }}
                      >
                        {`${Math.round(row.processRatio)}%`}
                      </Typography>
                    </LinearProgress>
                  </td>
                  <td>{row.standartTime}</td>
                  <td>{row.estimatedTime}</td>
                  <td>{row.actualTime}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
