import axios from "axios";
const apiUrl2 = process.env.REACT_APP_APIINTRA;
const apiUrl = process.env.REACT_APP_APIEAWB;
export const getMawbInfo = async (mawb, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/spc/mawbInfo?mawb=${mawb}`, {
      method: "GET",
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "No Data" });
    } else {
      if (res.errorFlag === "Y") {
        throw new Error(res.data);
      } else {
        if (res.errCode && res.errCode === "NODATA") {
          setLoadFlag(false);
          return "NODATA";
        } else {
          const data = res.data.map((row) => ({
            mainEntities: row.mainEntities,
            subEntities:
              row.subEntities &&
              row.subEntities.map((subRow, index) => ({
                id: index,
                agencyFileSid: subRow.agencyFileSid,
                fileExtension: subRow.fileExtension,
                airSid: subRow.airSid,
                spcType: subRow.spcType,
                fileName: subRow.fileName,
              })),
          }));

          setLoadFlag(false);
          return data;
        }
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "위험물등록ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getCommonDG = async (type) => {
  try {
    const response = await fetch(`${apiUrl2}/common/commonList?type=${type}`, {
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errorFlag === "Y") {
        throw new Error(res.data);
      } else {
        const data = res.data.map((row) => ({
          value1Char: row.value1Char,
          value2Char: row.value2Char,
          value3Char: row.value3Char,
          value4Char: row.value4Char,
        }));

        return data;
      }
    }
  } catch (error) {}
  return false;
};

export const setSpcSaveClient = async (formData, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await axios.post(`${apiUrl}/spc/saveClient`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: sessionStorage.getItem("token"),
      },
    });

    const res = await response.data;
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        throw new Error(res.data);
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "위험물등록ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getFileInfo = async (fileGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await axios.get(
      `${apiUrl}/spc/readFile?fileGuid=${fileGuid}`,
      {
        responseType: "arraybuffer",
      }
    );

    const contentType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: contentType });
    const url = URL.createObjectURL(blob);
    setLoadFlag(false);
    return url;
  } catch (error) {
    setErrFlag({ code: "Y", data: "위험물등록ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setFileDelete = async (agencyFileSid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await axios.get(
      `${apiUrl}/spc/deleteFile?agencyFileSid=${agencyFileSid}`
    );

    setLoadFlag(false);
    return true;
  } catch (error) {
    setErrFlag({ code: "Y", data: "위험물등록ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setSpcSaveDelete = async (spcGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/spc/delete?spcGuid=${spcGuid}`, {
      headers: { Authorization: sessionStorage.getItem("token") },
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        throw new Error(res.data);
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "위험물등록ERROR : " + error.message });
  }

  setLoadFlag(false);
  return false;
};

export const getAirList = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl2}/air/airList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          airSid: row.airSid,
          airSeq: row.airSeq,
          carrier2Code: row.carrier2Code,
          carrier3Code: row.carrier3Code,
          terminalCode: row.terminalCode,
          nationCode: row.nationCode,
          airPhone: row.airPhone,
          prefix: row.prefix,
          airName: row.airName,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
export const getAirSphCarrierList = async (
  carrierCode,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl2}/air/airSphCarrrierList?carrierCode=${carrierCode}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          airSpcSid: row.airSphSid,
          airSid: row.airSid,
          sphSeq: row.sphSeq,
          sphType: row.sphType,
          sphValue1: row.sphValue1,
          usableFlag: row.usableFlag,
          createTime: row.createTime,
          requiredFlag: row.requiredFlag,
          flag: "N",
          agencyFileSid: null,
          fileName: "",
          fileExtension: "",
          file: null,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getAirSphCarrierListGroup = async (
  airSid,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl2}/air/airSphCarrierGroup?airSid=${airSid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          type: row.type,
          docList: row.docList,

          originFileList: [],
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getAirCombo = async () => {
  try {
    const response = await fetch(`${apiUrl2}/air/airList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          value1: index,
          guid: row.airSid,
          value2: row.carrier2Code,
          value3: row.airName,
          terminalCode: row.terminalCode,
          airPhone: row.airPhone,
        }));
        return data;
      }
    }
  } catch (error) {}
  return false;
};

export const getCommonList = async (type, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl2}/common/commonList?type=${type}`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          commonSid: row.commonSid,
          commonSeq: row.commonSeq,
          commonType: row.commonType,
          value1Char: row.value1Char,
          value2Char: row.value2Char,
          value3Char: row.value3Char,
          value4Char: row.value4Char,
          changeFlag: "N",
          usableFlag: row.usableFlag,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
