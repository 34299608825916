import { Box, Button, Table, Typography } from "@mui/joy";
import {
  Modal,
  Paper,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  ownerDocument,
} from "@mui/material";
import React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TrackingStep from "./TrackingStep";
import { styled } from "@mui/material/styles";
import "./css/TrackCss.css";
import { useState } from "react";
import TrackBlSearchModal from "./TrackBlSearchModal";
import moment from "moment";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import {
  blSearchClick,
  traceResultAPI,
  getEawbFwbDown,
  getEawbFhlDown,
} from "./API/TrackAPI";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #343a40",
  boxShadow: 24,
  p: "1%",
  borderRadius: "10px",
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4472C4",
    color: theme.palette.common.white,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
}));

export default function Tracking() {
  const [blNum, setBlNum] = useState("");
  const [blNumArray, setBlNumArray] = useState([]);
  const [blModal, setBlModal] = useState(false);
  const [trackDataArray, setTrackDataArray] = useState([]);
  const { setErrFlag, setLoadFlag, setCompleteCode } = useContext(AppContext);

  const blNumbChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^a-zA-Z0-9]/g, "");
    setBlNum(sanitizedInput);
  };
  const handleClose = () => {
    setBlModal(false);
  };

  const blButtonClick = async () => {
    blSearchClick(blNum, setErrFlag, setLoadFlag)
      .then((result) =>
        result && result.length > 1
          ? (setBlNumArray(result), setBlModal(true))
          : blSearchData(result[0])
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const blSearchData = (item) => {
    if (Object.values(item).length > 0) {
      setBlModal(false);
      traceResultAPI(item.cargoSid, setErrFlag, setLoadFlag)
        .then((result) => result && setTrackDataArray(result))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  };

  const fwbDown = () => {
    const mawb =
      trackDataArray[0].mawb.slice(0, 3) +
      "-" +
      trackDataArray[0].mawb.slice(3);
    let arrayBuffer = null;
    getEawbFwbDown(mawb, setErrFlag, setLoadFlag)
      .then((result) => {
        if (result) {
          arrayBuffer = result;
        }
      })
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    const blob = new Blob([arrayBuffer], {
      type: "application/pdf;charset=UTF-8",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download =
      "TrackingFWB_" + moment(new Date()).format("YYYYMMDD_HHmmsss"); //matches[1].replace(/['"]/g, ""); // 파일명 지정
    link.click();
  };

  const fhlDown = async () => {
    let arrayBuffer = null;
    getEawbFhlDown(trackDataArray, setErrFlag, setLoadFlag)
      .then((result) => {
        if (result) {
          arrayBuffer = result;
        }
      })
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    const blob = new Blob([arrayBuffer], {
      type: "application/pdf;charset=UTF-8",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download =
      "TrackingFHL_" + moment(new Date()).format("YYYYMMDD_HHmmsss"); //matches[1].replace(/['"]/g, ""); // 파일명 지정
    link.click();
  };

  return (
    <div>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            paddingTop: "6%",
            paddingLeft: "15%",
            paddingRight: "15%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                paddingTop: "1.2%",
                marginRight: "1%",
                marginLeft: "1.7%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
              level="h4"
              variant="plain"
            >
              화물번호 &nbsp;(&nbsp;MAWB&nbsp; /&nbsp; HAWB&nbsp;)
            </Typography>

            <TextField
              id="standard-basic"
              label="MAWB / HAWB"
              variant="standard"
              value={blNum}
              onChange={blNumbChange}
            />
            <Box sx={{ paddingTop: "1%", marginLeft: "1%" }}>
              <Button
                size="md"
                variant="outlined"
                sx={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}
                onClick={blButtonClick}
              >
                조&nbsp;회
              </Button>
            </Box>
            <Modal
              open={blModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box style={style}>
                <TrackBlSearchModal
                  searchArray={blNumArray}
                  blMdRes={blSearchData}
                />
              </Box>
            </Modal>
          </Box>
          <Paper
            elevation={3}
            sx={{
              margin: "1.7%",
              textAlign: "right",
              backgroundColor: "rgba(255, 255, 255, 50%)",
            }}
          >
            <Button
              size="sm"
              color="neutral"
              sx={{
                margin: "0.5%",
                marginRight: "1%",
                paddingLeft: "3%",
                paddingRight: "3%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
              onClick={fwbDown}
            >
              E-AWB(FWB)
            </Button>
            <Button
              size="sm"
              color="neutral"
              sx={{
                margin: "0.5%",
                marginRight: "3%",
                paddingLeft: "3%",
                paddingRight: "3%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
              onClick={fhlDown}
            >
              E-AWB(FHL)
            </Button>
          </Paper>

          <Paper
            sx={{
              margin: "1.7%",
              backgroundColor: "rgba(255, 255, 255, 50%)",
              display: "flex",
            }}
            elevation={3}
          >
            <Box
              sx={{
                width: 450,
                margin: "1.5%",
                marginLeft: "5%",
              }}
            >
              <table className="inputTable">
                <tr>
                  <th>MAWB No.</th>
                  <td>
                    {trackDataArray.length > 0 &&
                      trackDataArray[0].mawb.slice(0, 3) +
                        " - " +
                        trackDataArray[0].mawb.slice(3)}
                  </td>
                </tr>
                <tr>
                  <th>Booking Date.</th>
                  <td>
                    {trackDataArray.length > 0 && trackDataArray[0].fltDate}
                  </td>
                </tr>
                <tr>
                  <th>Origin</th>
                  <td>
                    {trackDataArray.length > 0 && trackDataArray[0].orgin}
                  </td>
                </tr>
                <tr>
                  <th>Pieces</th>
                  <td>
                    {trackDataArray.length > 0 &&
                      trackDataArray[0].pieces + "PCS"}
                  </td>
                </tr>
              </table>
            </Box>
            <Box
              sx={{
                width: 450,
                margin: "1.5%",
                marginRight: "5%",
                marginLeft: "auto",
              }}
            >
              <table className="inputTable2">
                <tr>
                  <th>HAWB No.</th>
                  <td>{trackDataArray.length > 0 && trackDataArray[0].hawb}</td>
                </tr>
                <tr>
                  <th>배정장소</th>
                  <td>
                    {trackDataArray.length > 0 && trackDataArray[0].assignCode}
                  </td>
                </tr>
                <tr>
                  <th>위치</th>
                  <td>
                    {trackDataArray.length > 0 && trackDataArray[0].location}
                  </td>
                </tr>
                <tr>
                  <th>Weight(Kg)</th>
                  <td>
                    {trackDataArray.length > 0 &&
                      trackDataArray[0].weight + "KG"}
                  </td>
                </tr>
              </table>
            </Box>
          </Paper>
          <Paper
            elevation={3}
            sx={{ margin: "1.5%", backgroundColor: "#00498C" }}
          >
            <Typography
              level="h3"
              sx={{
                color: "white",
                padding: "1%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            >
              Track Shipment&nbsp;(&nbsp;ICN&nbsp;)&nbsp; -&nbsp;{" "}
              {trackDataArray.length > 0 && trackDataArray[0].pieces + "PCS"}
              &nbsp; /&nbsp;{" "}
              {trackDataArray.length > 0 && trackDataArray[0].weight + "KG"}
            </Typography>
          </Paper>
          <Paper
            elevation={3}
            sx={{ margin: "1.5%", backgroundColor: "#DAE3F3DD" }}
          >
            <TrackingStep
              props={trackDataArray.length > 0 && trackDataArray[0]}
            />
          </Paper>
          <Paper
            elevation={3}
            sx={{ margin: "1.5%", backgroundColor: "#00498C" }}
          >
            <Typography
              level="h3"
              sx={{
                color: "white",
                padding: "1%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            >
              Details
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ margin: "1.5%" }}>
            <Table stripe="odd">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ border: 2 }}>발생시간</StyledTableCell>
                  <StyledTableCell>상태</StyledTableCell>
                  <StyledTableCell>D/O 여부</StyledTableCell>
                  <StyledTableCell>상세목록</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trackDataArray.length > 0 &&
                  trackDataArray[0].statusLog.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.cargoStatusTime}</TableCell>
                      <TableCell>{row.cargoStatusName}</TableCell>
                      <TableCell>{row.deliverOrderNo}</TableCell>
                      <TableCell>{row.remark}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Box>
    </div>
  );
}
