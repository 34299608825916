import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";

const AACTLocation = () => {
  useEffect(() => {
    // Kakao 지도 API 스크립트 동적으로 추가
    const script = document.createElement("script");
    script.src =
      "https://dapi.kakao.com/v2/maps/sdk.js?appkey=d1d94a8632e83fc491529ff3d6accb9c&libraries=services";
    document.head.appendChild(script);

    script.onload = () => {
      // Kakao 객체가 로드된 후에 실행되는 코드

      const mapContainer = document.getElementById("map");
      const mapOption = {
        center: new window.kakao.maps.LatLng(
          37.47096804488217,
          126.46179069709427
        ),
        level: 4,
      };
      var map = new window.kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
      var positions = [
        {
          title: "AACT 제 1 화물터미널",
          latlng: new window.kakao.maps.LatLng(
            37.46996602599905,
            126.45841506847209
          ),
          content:
            "<div style='padding:30px 30px 50px 30px; font-size: smaller;'>주소 : 인천 중구 공항동로 295번길 124 (우)22380 <br />전화 : 032-744-4820 <br />FAX : 032-744-1320</div>",
        },
        {
          title: "AACT 제 2 화물터미널",
          latlng: new window.kakao.maps.LatLng(
            37.4730780830424,
            126.46414674349359
          ),
          content:
            "<div style='padding:30px 30px 50px 30px; font-size: smaller;'>주소 : 인천 중구 공항동로 295번길 60 (우)22380 <br />전화 : 032-744-4821 <br />FAX : 032-744-1320</div>",
        },
      ];

      var imageSrc =
        "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";

      for (var i = 0; i < positions.length; i++) {
        // 마커 이미지의 이미지 크기 입니다
        var imageSize = new window.kakao.maps.Size(24, 35);

        // 마커 이미지를 생성합니다
        var markerImage = new window.kakao.maps.MarkerImage(
          imageSrc,
          imageSize
        );

        // 마커를 생성합니다
        var marker = new window.kakao.maps.Marker({
          map: map, // 마커를 표시할 지도
          position: positions[i].latlng, // 마커를 표시할 위치
          title: positions[i].title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
          image: markerImage, // 마커 이미지
          clickable: true,
        });
        marker.setMap(map);

        var iwContent = positions[i].content,
          iwRemoveable = true;

        var infowindow = new window.kakao.maps.InfoWindow({
          content: iwContent,
          removable: iwRemoveable,
        });

        window.kakao.maps.event.addListener(
          marker,
          "click",
          makeClickListener(map, marker, infowindow)
        );
      }

      var zoomControl = new window.kakao.maps.ZoomControl();
      map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);
    };
  }, []);

  function makeClickListener(map, marker, infowindow) {
    return function () {
      infowindow.open(map, marker);
    };
  }

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "inherit",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "8%",
          paddingLeft: "15%",
          paddingRight: "15%",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            marginBottom: "3%",
            width: "50%",
            backgroundColor: "#073A6890",
            padding: "1%",
            borderRadius: "15px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Noto Sans KR', sans-serif",
              fontSize: "24px",
              color: "white",
            }}
          >
            AACT 위치
          </Typography>
        </Paper>
        <Paper
          sx={{ display: "flex", backgroundColor: "#ffffff90" }}
          elevation={3}
        >
          <div
            id="map"
            style={{
              width: "70%",
              height: "600px",
              zIndex: 0,
              borderRadius: "5px",
            }}
          ></div>
          <Box sx={{ width: "30%" }}>
            <Typography
              sx={{
                fontFamily: "'Noto Sans KR', sans-serif",
                fontSize: "18px",
                fontWeight: "bold",
                borderBottom: 2,
                margin: "2%",
                padding: "2%",
                marginTop: "5%",
              }}
            >
              - AACT 제 1 화물터미널
            </Typography>
            <Box sx={{ marginLeft: "3%" }}>
              <Typography
                sx={{
                  fontFamily: "'Noto Sans KR', sans-serif",
                  fontSize: "15px",
                }}
              >
                주 소 : 인천광역시 중구 공항동로 295번길 124
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Noto Sans KR', sans-serif",
                  fontSize: "15px",
                }}
              >
                전 화 : 032-744-4820
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Noto Sans KR', sans-serif",
                  fontSize: "15px",
                }}
              >
                F A X : 032-744-1320
              </Typography>
            </Box>

            <Typography
              sx={{
                fontFamily: "'Noto Sans KR', sans-serif",
                fontSize: "18px",
                fontWeight: "bold",
                borderBottom: 2,
                margin: "2%",
                padding: "2%",
                marginTop: "40%",
              }}
            >
              - AACT 제 2 화물터미널
            </Typography>
            <Box sx={{ marginLeft: "3%" }}>
              <Typography
                sx={{
                  fontFamily: "'Noto Sans KR', sans-serif",
                  fontSize: "15px",
                }}
              >
                주 소 : 인천광역시 중구 공항동로 295번길 60
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Noto Sans KR', sans-serif",
                  fontSize: "15px",
                }}
              >
                전 화 : 032-744-4821
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Noto Sans KR', sans-serif",
                  fontSize: "15px",
                }}
              >
                F A X : 032-744-1320
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AACTLocation;
