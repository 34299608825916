import { Box, Paper } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getAirSphCarrierListGroup } from "./API/SpcAPI";
import "animate.css";
import SpcFileInfo from "./SpcFileInfo";
import { useRef } from "react";

export default function SpcFile({ originData, originFile, sendFileData }) {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [contentOrigin, setContentOrigin] = useState();
  const [uploadColumn, setUploadColumn] = useState();
  const [spcType, setSpcType] = useState("");
  const spcFileInfoRef = useRef(null);
  useEffect(() => {
    if (originData && originData.airSid && originFile) {
      modalClose();
      getAirSphCarrierListGroup(originData.airSid, setErrFlag, setLoadFlag)
        .then(
          (result) =>
            result &&
            setContentOrigin(
              result.map((item) => {
                const tempFile = originFile.filter(
                  (itemB) =>
                    itemB.spcType === item.type &&
                    itemB.airSid === originData.airSid
                );

                if (tempFile) {
                  const retData = {
                    ...item,
                    originFileList: tempFile,
                  };
                  return retData;
                } else {
                  return item;
                }
              })
            )
        )
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    } else {
      setContentOrigin([]);
    }
  }, [originData.airSid, originFile]);

  const modalOpen = (type) => {
    setSpcType(type);
    const tempData = contentOrigin.filter((item) => item.type === type);
    setUploadColumn(tempData);
    if (spcFileInfoRef.current) {
      spcFileInfoRef.current.scrollIntoView({ behavior: "smooth" });
      spcFileInfoRef.current.focus();
    }
  };
  const modalClose = () => {
    setUploadColumn(null);
    setSpcType(null);
  };

  useEffect(() => {
    if (spcType) {
      modalOpen(spcType);
      sendFileData(contentOrigin);
    }
  }, [contentOrigin, spcType]);
  const fileRecv = (value) => {
    if (value.flag === "I") {
      const tempData = contentOrigin.map((item) => {
        if (item.type === value.type) {
          const tempObj = [...item.originFileList];
          var count = tempObj.length;
          Array.from(value.files).forEach((fileRow) => {
            tempObj.push({
              id: count,
              agencyFileSid: null,
              fileName: fileRow.name,
              fileExtension: String(fileRow.name).split(".")[1].toUpperCase(),
              file: fileRow,
              flag: "I",
            });
            count++;
          });
          const tempArray = { ...item, originFileList: tempObj };
          return tempArray;
        } else {
          return item;
        }
      });
      setContentOrigin(tempData);
    }
    if (value.flag === "D") {
      const tempData = contentOrigin.map((item) => {
        if (item.originFileList && item.type === value.type) {
          const tempFile = item.originFileList.map((fileRow) => {
            if (fileRow.id === value.files) {
              return { ...fileRow, flag: value.flag };
            } else {
              return { ...fileRow };
            }
          });
          return { ...item, originFileList: tempFile };
        } else {
          return item;
        }
      });

      setContentOrigin(tempData);
    }
    if (value.flag === "R") {
      const tempData = contentOrigin.map((item) => {
        if (item.originFileList && item.type === value.type) {
          const tempFile = item.originFileList.map((fileRow) => {
            if (fileRow.id === value.files) {
              return { ...fileRow, flag: "I" };
            } else {
              return { ...fileRow };
            }
          });
          return { ...item, originFileList: tempFile };
        } else {
          return item;
        }
      });
      setContentOrigin(tempData);
    }
  };
  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          mt: 5,
          mb: 3,
          backgroundColor: "#00114F20",
          display: "flex",
          alignItems: "center",
          pt: 0.5,
          pb: 0.5,
        }}
      >
        <ArrowRightIcon fontSize="large" sx={{ marginLeft: "1.7%" }} />
        <Typography
          sx={{
            fontSize: 18,
            fontFamily: "Noto Sans KR",
            fontWeight: 500,
          }}
        >
          서류 등록
        </Typography>

        <Typography
          sx={{
            p: "0.5%",
            mr: 5,
            ml: "auto",
            mb: "auto",
            mt: "auto",
            fontSize: 17,
            fontFamily: "Noto Sans KR",
            fontWeight: 500,
          }}
        >
          ※ 반드시 한 가지 항목 이상의 파일을 업로드 해주세요.
        </Typography>
      </Paper>

      <Box
        sx={{
          display: "grid",
          flexWrap: "wrap",
          gridTemplateColumns: `repeat(${Math.ceil(
            contentOrigin && contentOrigin.length / 2
          )},1fr)`,
          gap: 1,
        }}
      >
        {contentOrigin &&
          contentOrigin.map((item) => (
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                cursor: originData.agencyFlag !== "Y" && "pointer",
                pt: 1,
                pb: 1,
                border: "1px solid #CCC",
                backgroundColor:
                  spcType && spcType === item.type
                    ? "#e9ecef"
                    : "rgba(255, 255, 255, 70%)",
                "&:hover": originData.agencyFlag !== "Y" && {
                  backgroundColor: "#e9ecef",
                },
              }}
              onClick={(e) =>
                originData.agencyFlag !== "Y" &&
                (modalOpen(item.type), e.stopPropagation())
              }
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Noto Sans KR" }}
                >
                  {item.type}
                </Typography>
                {item.originFileList &&
                  Array.from(item.originFileList).filter(
                    (item) => item.flag !== "D"
                  ).length > 0 && (
                    <TaskAltIcon color="success" sx={{ ml: 2 }} />
                  )}
              </Box>
            </Paper>
          ))}
      </Box>
      {uploadColumn && (
        <SpcFileInfo
          data={uploadColumn}
          sendFile={fileRecv}
          forwardedRef={spcFileInfoRef}
        />
      )}
    </Box>
  );
}
