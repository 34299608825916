import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [errFlag, setErrFlag] = useState({ code: "N", data: "" });
  const [loadFlag, setLoadFlag] = useState(false);
  const [completeCode, setCompleteCode] = useState(false);
  const [saveFlag, setSaveFlag] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);

  return (
    <AppContext.Provider
      value={{
        errFlag,
        setErrFlag,
        loadFlag,
        setLoadFlag,
        completeCode,
        setCompleteCode,
        saveFlag,
        setSaveFlag,
        deleteFlag,
        setDeleteFlag,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
