import { Button, OutlinedInput, TextField, styled } from "@mui/material";

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },

  "& input": {
    fontFamily: "Noto Sans KR",
    textAlign: "right",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

export const CssInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E3E7", // 기본 테두리 색깔
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6F7E8C", // 포커스 시 테두리 색깔
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B2BAC2", // 마우스 hover 시 테두리 색깔
  },
}));

export const SearchButton = styled(Button)(({ theme }) => ({
  fontFamily: "Noto Sans KR",
  fontWeight: 500,
  color: "#00114F",
  borderColor: "#00114F",
  "&:hover": {
    borderColor: "#00114F",
    backgroundColor: "#EFF3FA",
  },
}));

export const SaveButton = styled(Button)(({ theme, fontWT }) => ({
  fontFamily: "Noto Sans KR",
  fontWeight: fontWT || 400,
  color: "#fff",
  backgroundColor: "#00114F",
  "&:hover": {
    backgroundColor: "#00114FCC",
  },
}));

export const CancelButton = styled(Button)(({ theme, fontWT }) => ({
  fontFamily: "Noto Sans KR",
  fontWeight: fontWT || 400,
  color: "#00114F",
  borderColor: "#00114F",
  "&:hover": {
    borderColor: "#00114F",
    backgroundColor: "#EFF3FA",
  },
}));

export const UploadButton = styled(Button)(({ theme }) => ({
  fontFamily: "Noto Sans KR",
  fontWeight: 500,
  color: "#00114F",
  borderColor: "#00114F",
  "&:hover": {
    borderColor: "#00114F",
    backgroundColor: "#EFF3FA",
  },
}));

export const CancelButtonTablet = styled(Button)(({ theme }) => ({
  color: "#00114F",
  backgroundColor: "#D7E2F2",
  "&:hover": {
    backgroundColor: "#D7E2F2",
  },
}));
