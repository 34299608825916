import { Check, Close } from "@mui/icons-material";
import {
  Alert,
  AspectRatio,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/joy";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
export default function CompleteTablet({ props }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props);
  }, [props]);
  const closeSucBtn = () => {
    setOpen(false);
  };
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: "auto",
        right: 0,
        padding: "16px",
        width: "50%",
        opacity: 1,
        display: open === true ? "block" : "none",
      }}
    >
      <Alert
        size="lg"
        color="success"
        variant="solid"
        invertedColors
        startDecorator={
          <AspectRatio
            variant="solid"
            ratio="1"
            sx={{
              minWidth: 40,
              borderRadius: "50%",
              boxShadow: "0 2px 12px 0 rgb(0 0 0/0.2)",
            }}
          >
            <div>
              <Check fontSize="xl2" />
            </div>
          </AspectRatio>
        }
        endDecorator={
          <IconButton
            variant="plain"
            sx={{
              "--IconButton-size": "32px",
              transform: "translate(0.5rem, -0.5rem)",
            }}
            onClick={(e) => (e.stopPropagation(), closeSucBtn())}
          >
            <Close />
          </IconButton>
        }
        sx={{ alignItems: "flex-start", overflow: "hidden" }}
      >
        <div>
          <Typography level="title-lg">Success</Typography>
          <Typography level="body-sm">
            Success is walking from failure to failure with no loss of
            enthusiam.
          </Typography>
        </div>
        <LinearProgress
          variant="solid"
          color="success"
          value={40}
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
          }}
        />
      </Alert>
    </div>
  );
}
