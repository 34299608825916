import { Box, Button } from "@mui/joy";
import React from "react";
import Calendar from "react-calendar";
import "../css/CalenderCust.css";
import moment from "moment/moment";
import { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function CalenderTime({ props }) {
  const [selectData, setSelectData] = useState(null);
  const [selectTime, setSelectTime] = useState(null);
  const [timeOpen, setTimeOpen] = useState(false);
  const clickCalendar = (data) => {
    setSelectData(data);
    props({ date: moment(data).format("YYYY-MM-DD") });
    setSelectTime(null);
    setTimeOpen(true);
  };
  const clickTime = (data) => {
    setSelectTime(data);
    props({ time: data.$d });
  };

  const timeClose = () => {
    setTimeOpen(false);
    props({ close: false });
  };
  return (
    <Box>
      <Calendar
        value={selectData}
        onChange={clickCalendar}
        formatDay={(locale, date) => moment(date).format("DD")}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["TimePicker"]}>
          <TimePicker
            sx={{ width: "100%" }}
            value={selectTime}
            slotProps={{
              textField: { size: "small" },
            }}
            onChange={(newValue) => clickTime(newValue)}
            open={timeOpen}
            onClose={timeClose}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}
