import { Box, Button, CssBaseline, Divider } from "@mui/joy";
import {
  AppBar,
  Card,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import "../css/AppBar.css";
import logo from "../images/tabletHeaderLogo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import TrackIcon from "../images/TrackIcon2.png";
import InqueryIcon from "../images/InqueryIcon2.png";
import SpcIcon from "../images/DgIcon.png";
import BoardIcon from "../images/BoardIcon2.png";
import LocationIcon from "../images/LocationIcon2.png";
import SitesIcon from "../images/SitesHeader.png";
import BackImage from "../images/AppBarBack.png";
import { useEffect } from "react";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useContext } from "react";
import { AppContext } from "../AppContext";
export default function MainAppBarTablet() {
  const history = useNavigate();
  const [isFixed, setIsFixed] = useState(true);
  const [open, setOpen] = useState(false);
  const { setErrFlag, setLoadFlag, setCompleteCode } = useContext(AppContext);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset <= 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const reset = () => {
    setErrFlag({ code: "N", data: "" });
    setLoadFlag(false);
    setCompleteCode(false);
  };

  const linkHome = () => {
    reset("Y");
    setOpen(false);

    history("/");
  };
  const linkTrack = () => {
    reset("Y");
    setOpen(false);
    history("/Tracking");
  };
  const linkInquery = () => {
    reset("Y");
    setOpen(false);
    history("/Inquery");
  };
  const linkSpc = () => {
    reset("Y");
    setOpen(false);
    history("/Spc");
  };
  const linkFlight = () => {
    reset("Y");
    setOpen(false);
    history("/Flight");
  };
  const linkLocation = () => {
    reset("Y");
    setOpen(false);
    history("/Location");
  };
  const linkSites = () => {
    reset("Y");
    setOpen(false);
    history("/Sites");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const DrawerList = (
    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(${BackImage})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
      }}
      role="presentation"
      onClick={handleDrawerClose}
    >
      <List>
        {[
          "화물 추적",
          "창고료 조회",
          "특수 화물 등록",
          "스케줄 조회",
          "AACT 위치",
          "관련 사이트",
        ].map((text, index) => (
          <ListItem
            sx={{
              width: "40%",
              marginLeft: "10%",
            }}
            key={text}
            disablePadding
          >
            <ListItemButton
              sx={{
                justifyContent: "left",
                borderBottom: 2,
                borderBottomColor: "white",
              }}
            >
              {index === 0 && (
                <img
                  src={TrackIcon}
                  className="slide-left"
                  width={"30%"}
                  onClick={(e) => (e.stopPropagation(), linkTrack())}
                />
              )}
              {index === 1 && (
                <img
                  src={InqueryIcon}
                  className="slide-left"
                  width={"30%"}
                  onClick={(e) => (e.stopPropagation(), linkInquery())}
                />
              )}
              {index === 2 && (
                <img
                  src={SpcIcon}
                  className="slide-left"
                  width={"30%"}
                  onClick={(e) => (e.stopPropagation(), linkSpc())}
                />
              )}
              {index === 3 && (
                <img
                  src={BoardIcon}
                  className="slide-left"
                  width={"30%"}
                  onClick={(e) => (e.stopPropagation(), linkFlight())}
                />
              )}
              {index === 4 && (
                <img
                  src={LocationIcon}
                  className="slide-left"
                  width={"30%"}
                  onClick={(e) => (e.stopPropagation(), linkLocation())}
                />
              )}
              {index === 5 && (
                <img
                  src={SitesIcon}
                  className="slide-left"
                  width={"30%"}
                  onClick={(e) => (e.stopPropagation(), linkSites())}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box>
      <CssBaseline />
      <AppBar
        position={isFixed ? "fixed" : "static"}
        sx={{
          top: 0,
          bottom: "auto",
          display: isFixed ? "block" : "none",
          backgroundColor: "rgba(255, 255, 255, 0)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ position: "absolute", left: "1vw" }}>
            <DehazeIcon
              sx={{
                color: "#000",
                fontSize: "2.0rem",
              }}
              onClick={handleDrawerOpen}
            />
          </Box>
          <Box sx={{ width: "17%" }}>
            <img
              src={logo}
              width={"100%"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{ cursor: isHovered ? "pointer" : "default" }}
              onClick={linkHome}
              alt="logo"
            />
          </Box>
        </Box>
      </AppBar>
      <Drawer anchor="top" open={open} onClose={handleDrawerClose}>
        {DrawerList}
      </Drawer>
    </Box>
  );
}
