import { Box, Button, CircularProgress } from "@mui/joy";
import React from "react";
import MainAppBar from "./MainAppBar";
import MuiDrawer from "@mui/material/Drawer";
import { Backdrop, styled } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SubMain from "./SubMain";
import { useLocation, useParams } from "react-router-dom";
import Tracking from "./Tracking";
import Inquery from "./Inquery";
import { useEffect } from "react";
import { useState } from "react";
import MainAppBarMobile from "./mobile/MainAppBarMobile";
import SubMainMobile from "./mobile/SubMainMobile";
import Fail from "./util/Fail";
import Complete from "./util/Complete";
import SubMainTablet from "./tablets/SubMainTablet";
import MainAppBarTablet from "./tablets/MainAppBarTablet";
import TrackingTablet from "./tablets/TrackingTablet";
import TrackingMobile from "./mobile/TrackingMobile";
import InqueryTablet from "./tablets/InqueryTablet";
import InqueryMobile from "./mobile/InqueryMobile";
import Sites from "./Sites";
import SitesMobile from "./mobile/SitesMobile";
import SitesTablet from "./tablets/SitesTablet";
import CargoSchedule from "./CargoSchedule";
import CargoScheduleTablet from "./tablets/CargoScheduleTablet";
import CargoScheduleMobile from "./mobile/CargoScheduleMobile";
import AACTLocation from "./AACTLocation";
import AACTLocationTablet from "./tablets/AACTLocationTablet";
import AACTLocationMobile from "./mobile/AACTLocationMobile";
import FailTablet from "./util/FailTablet";
import FailMobile from "./util/FailMobile";
import CompleteTablet from "./util/CompleteTablet";
import CompleteMobile from "./util/CompleteMobile";
import Loading from "./util/Loading";
import { useContext } from "react";
import { AppContext, AppProvider } from "./AppContext";
import SpcMain from "./SpcMain";
import SpcMawbInfo from "./SpcMawbInfo";
import SpcMainTablet from "./tablets/SpcMainTablet";
import SpcMawbInfoTablet from "./tablets/SpcMawbInfoTablet";
import SpcMainMobile from "./mobile/SpcMainMobile";
import SpcMawbInfoMobile from "./mobile/SpcMawbInfoMobile";

const drawerWidth = 400;
//주영이 들어옴
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc( 0px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function Main() {
  const location = useLocation();
  const {
    errFlag,
    setErrFlag,
    loadFlag,
    setLoadFlag,
    completeCode,
    setCompleteCode,
  } = useContext(AppContext);
  const [maxWidth, setMaxWidth] = useState(false);
  const [normalWidth, setNormalWidth] = useState(false);
  const [minWidth, setMinWidth] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1024) {
        setMaxWidth(true);
        setNormalWidth(false);
        setMinWidth(false);
      } else if (width <= 1024 && width > 500) {
        setMaxWidth(false);
        setNormalWidth(true);
        setMinWidth(false);
      } else if (width < 500) {
        setMaxWidth(false);
        setNormalWidth(false);
        setMinWidth(true);
      }
    };

    handleResize(); // 초기 렌더링 시에도 실행

    window.addEventListener("resize", handleResize); // 창 크기 변경 시에도 실행

    return () => {
      window.removeEventListener("resize", handleResize); // 컴포넌트 언마운트 시 이벤트 리스너 제거
    };
  }, []);
  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
      }}
    >
      {/* 헤더부분 */}
      {location.pathname !== "/" && maxWidth && <MainAppBar />}
      {location.pathname !== "/" && minWidth && <MainAppBarMobile />}
      {location.pathname !== "/" && normalWidth && <MainAppBarTablet />}
      {/* 메인페이지 */}
      {location.pathname === "/" && minWidth && <SubMainMobile />}
      {location.pathname === "/" && maxWidth && <SubMain />}
      {location.pathname === "/" && normalWidth && <SubMainTablet />}
      {/* 화물추적 페이지 */}
      {location.pathname === "/Tracking" && maxWidth && <Tracking />}
      {location.pathname === "/Tracking" && normalWidth && <TrackingTablet />}
      {location.pathname === "/Tracking" && minWidth && <TrackingMobile />}
      {/* 창고료 조회 페이지 */}
      {location.pathname === "/Inquery" && maxWidth && <Inquery />}
      {location.pathname === "/Inquery" && normalWidth && <InqueryTablet />}
      {location.pathname === "/Inquery" && minWidth && <InqueryMobile />}
      {/* 스케줄 조회 페이지 */}
      {location.pathname === "/Flight" && maxWidth && <CargoSchedule />}
      {location.pathname === "/Flight" && normalWidth && (
        <CargoScheduleTablet />
      )}
      {location.pathname === "/Flight" && minWidth && <CargoScheduleMobile />}
      {/* AACT 위치 페이지 */}
      {location.pathname === "/Location" && maxWidth && <AACTLocation />}
      {location.pathname === "/Location" && normalWidth && (
        <AACTLocationTablet />
      )}
      {location.pathname === "/Location" && minWidth && <AACTLocationMobile />}

      {/* 에러처리 페이지 */}
      {maxWidth && <Fail props={errFlag} />}
      {normalWidth && <FailTablet props={errFlag} />}
      {minWidth && <FailMobile props={errFlag} />}

      {maxWidth && <Loading load={loadFlag} />}

      {maxWidth && <Complete props={completeCode} />}
      {normalWidth && <CompleteTablet props={completeCode} />}
      {minWidth && <CompleteMobile props={completeCode} />}
      {/* 관련사이트 페이지 */}
      {location.pathname === "/Sites" && maxWidth && <Sites />}
      {location.pathname === "/Sites" && normalWidth && <SitesTablet />}
      {location.pathname === "/Sites" && minWidth && <SitesMobile />}

      {/* DG 메인 */}
      {location.pathname === "/Spc" && maxWidth && <SpcMain />}
      {location.pathname === "/Spc" && normalWidth && <SpcMainTablet />}
      {location.pathname === "/Spc" && minWidth && <SpcMainMobile />}
      {/* DG 다음페이지 */}
      {location.pathname.startsWith("/SpcInfo") && maxWidth && <SpcMawbInfo />}
      {location.pathname.startsWith("/SpcInfo") && normalWidth && (
        <SpcMawbInfoTablet />
      )}
      {location.pathname.startsWith("/SpcInfo") && minWidth && (
        <SpcMawbInfoMobile />
      )}
    </Box>
  );
}

export default function AppWithProviders() {
  return (
    <AppProvider>
      <Main />
    </AppProvider>
  );
}
