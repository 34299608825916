const apiUrl = process.env.REACT_APP_APIEAWB;

export const getTokenData = async () => {
  try {
    const response = await fetch(`${apiUrl}/eawb/generateToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`${response.statusText}`);
    }
    const res = await response.text();
    if (res.length === 0) {
    } else {
      sessionStorage.setItem("token", res);
    }
  } catch (error) {
    console.log(error.message);
  }
};

export const getCode = async (classCode) => {
  try {
    const response = await fetch(
      `${apiUrl}/util/getCode?classCode=${classCode}`,
      { headers: { Authorization: sessionStorage.getItem("token") } }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errorFlag === "Y") {
        throw new Error(res.data);
      } else {
        const data = res.data.map((row) => ({
          codeSid: row.codeMaster.codeSid,
          code: row.codeMaster.codeCode,
        }));

        return data;
      }
    }
  } catch (error) {
    console.log(error.message);
  }
  return false;
};
