import { Box, Button } from "@mui/joy";
import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import icon01 from "../images/화물추적.png";
import icon02 from "../images/창고료.png";
import icon03 from "../images/schedule.jpg";
import icon04 from "../images/locationicon.jpg";
import icon05 from "../images/sitesicon.jpg";
import icon06 from "../images/SpcImg.jpg";
import TabletMain from "../images/TabletMain.gif";
import go from "../images/go.png";
import line from "../images/subMainLine.jpg";
import "../css/MainCss.css";
import { useState } from "react";
export default function SubMainTablet() {
  const history = useNavigate();
  const [change, setChange] = useState(true);

  const searchTracking = () => {
    setChange(false);
    setTimeout(() => {
      history("/Tracking");
    }, 800);
  };

  const searchInquery = () => {
    setChange(false);
    setTimeout(() => {
      history("/Inquery");
    }, 800);
  };

  const searchSpc = () => {
    setChange(false);
    setTimeout(() => {
      history("/Spc");
    }, 800);
  };

  const searchFlight = () => {
    setChange(false);
    setTimeout(() => {
      history("/Flight");
    }, 800);
  };

  const searchLocation = () => {
    setChange(false);
    setTimeout(() => {
      history("/Location");
    }, 800);
  };

  const searchSites = () => {
    setChange(false);
    setTimeout(() => {
      history("/Sites");
    }, 800);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          backgroundRepeat: "no-repeat",
          width: "100%",
          zIndex: -1,
        }}
      >
        <img
          style={{
            position: "absolute",
            left: 0,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: -1,
          }}
          src={TabletMain}
          width="100%"
        />
      </Box>
      <Box className={change === true ? "slide-up" : "slide-down"}>
        <Box sx={{ marginLeft: "5%", marginRight: "5%", paddingTop: "80%" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper
              elevation={3}
              sx={{
                width: "30%",
                height: 230,
                textAlign: "center",
                marginBottom: "10%",
                marginRight: "auto",
              }}
              onClick={searchTracking}
            >
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardMedia
                  sx={{ height: 120 }}
                  image={icon01}
                  title="green iguana"
                />
                <CardContent sx={{ padding: 0, paddingTop: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
                    >
                      화물 추적
                    </p>
                    <img
                      src={go}
                      alt="Go Icon"
                      style={{ width: 24, cursor: "pointer", marginTop: 1 }}
                    />
                  </div>
                  <img src={line} style={{ width: 70 }} />
                </CardContent>
              </Card>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                width: "30%",
                height: 230,
                textAlign: "center",
                marginBottom: 5,
              }}
              onClick={searchInquery}
            >
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardMedia
                  sx={{ height: 120 }}
                  image={icon02}
                  title="green iguana"
                />
                <CardContent sx={{ padding: 0, paddingTop: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
                    >
                      창고료 조회
                    </p>
                    <img
                      src={go}
                      alt="Go Icon"
                      style={{ width: 24, cursor: "pointer", marginTop: 1 }}
                    />
                  </div>
                  <img src={line} style={{ width: 70 }} />
                </CardContent>
              </Card>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                width: "30%",
                height: 230,
                textAlign: "center",
                marginBottom: 5,
                marginLeft: "auto",
              }}
              onClick={searchSpc}
            >
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardMedia
                  sx={{ height: 120 }}
                  image={icon06}
                  title="green iguana"
                />
                <CardContent sx={{ padding: 0, paddingTop: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
                    >
                      특수 화물 등록
                    </p>
                    <img
                      src={go}
                      alt="Go Icon"
                      style={{ width: 24, cursor: "pointer", marginTop: 1 }}
                    />
                  </div>
                  <img src={line} style={{ width: 70 }} />
                </CardContent>
              </Card>
            </Paper>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper
              elevation={3}
              sx={{
                width: "30%",
                height: 230,
                textAlign: "center",
                marginBottom: "10%",
                marginRight: "auto",
              }}
              onClick={searchFlight}
            >
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardMedia
                  sx={{ height: 120 }}
                  image={icon03}
                  title="green iguana"
                />
                <CardContent sx={{ padding: 0, paddingTop: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
                    >
                      스케줄 조회
                    </p>
                    <img
                      src={go}
                      alt="Go Icon"
                      style={{ width: 24, cursor: "pointer", marginTop: 1 }}
                    />
                  </div>
                  <img src={line} style={{ width: 70 }} />
                </CardContent>
              </Card>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                width: "30%",
                height: 230,
                textAlign: "center",
                marginBottom: 5,
              }}
              onClick={searchSites}
            >
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardMedia
                  sx={{ height: 120 }}
                  image={icon05}
                  title="green iguana"
                />
                <CardContent sx={{ padding: 0, paddingTop: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
                    >
                      관련 사이트
                    </p>
                    <img
                      src={go}
                      alt="Go Icon"
                      style={{ width: 24, cursor: "pointer", marginTop: 1 }}
                    />
                  </div>
                  <img src={line} style={{ width: 70 }} />
                </CardContent>
              </Card>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                width: "30%",
                height: 230,
                textAlign: "center",
                marginBottom: 5,
                marginLeft: "auto",
              }}
              onClick={searchLocation}
            >
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardMedia
                  sx={{ height: 120 }}
                  image={icon04}
                  title="green iguana"
                />
                <CardContent sx={{ padding: 0, paddingTop: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
                    >
                      AACT 위치
                    </p>
                    <img
                      src={go}
                      alt="Go Icon"
                      style={{ width: 24, cursor: "pointer", marginTop: 1 }}
                    />
                  </div>
                  <img src={line} style={{ width: 70 }} />
                </CardContent>
              </Card>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
