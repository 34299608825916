import {
  Box,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ScheduleBack from "./images/whiteBack.jpg";
import Arr from "./images/입항출항_Arr.png";
import Dep from "./images/입항출항_Dep.png";
import { useState } from "react";
import { useEffect } from "react";
import CalenderCust from "./CalenderCust";
import moment from "moment";
import CarrierSelect from "./CarrierSelect";
import Board from "./Board";
import { getCarrierCode, getSch } from "./API/ScheduleAPI";
import { useContext } from "react";
import { AppContext } from "./AppContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "1%",
};

export default function CargoSchedule() {
  const { setErrFlag, setLoadFlag, setCompleteCode } = useContext(AppContext);
  const [inoutFlag, setInoutFlag] = useState("I");
  const [isHovered, setIsHovered] = useState(false);
  const [carrierList, setCarrierList] = useState(true);
  const [dateCust, setDateCust] = useState(false);
  const [txtDate, setTxtDate] = useState("");
  const [txtCarrier, setCarrier] = useState("");
  const [openBoard, setOpenBoard] = useState(false);
  const [carrierData, setCarrierData] = useState([]);
  const [fatchCarrierData, setFatchCarrierData] = useState("");
  const [list, setList] = useState([]);

  const arrivalClick = () => {
    setInoutFlag("I");
  };

  const departureClick = () => {
    setInoutFlag("E");
  };

  const carrierTextClick = () => {
    setCarrierList(true);
  };
  const dateCustClick = () => {
    setDateCust(true);
  };
  const changeTxtDate = (props) => {
    setTxtDate(props);
  };
  const handleBoard = () => {
    const fltDate = moment(txtDate).format("YYYYMMDD");
    getSch(inoutFlag, fltDate, fatchCarrierData, setErrFlag, setLoadFlag)
      .then((result) => {
        if (result) {
          setList(result);
          setTxtDate("");
          setCarrier("");
        }
      })
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  useEffect(() => {
    if (list.length > 0) {
      setOpenBoard(true);
    } else {
      setOpenBoard(false);
    }
  }, [list]);
  const handleClose = () => setOpenBoard(false);

  const txtCarrierCode = (props) => {
    if (props.carrierCode.length > 0) {
      setCarrierList(false);
      setCarrier(getKorNameById(props.id));
      setFatchCarrierData(props.carrierCode);
    }
  };
  useEffect(() => {
    setTxtDate("");
    setInoutFlag("I");
    getCarrierCode(setErrFlag, setLoadFlag)
      .then((result) => result && setCarrierData(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#carrierTextField") &&
        !event.target.closest("#terminalButton")
      ) {
        setCarrierList(false);
      }

      if (!event.target.closest("#dateCustField")) {
        setDateCust(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [carrierList, dateCust]);
  const handleCalendarClick = (event) => {
    // 캘린더 내부를 클릭한 경우에는 컴포넌트가 사라지지 않도록 함
    event.stopPropagation();
  };

  const getKorNameById = (id) => {
    const foundCarrier = carrierData.find((item) => item.id === id);
    return foundCarrier ? foundCarrier.engName : "";
  };

  useEffect(() => {
    setCarrierList(true);
  }, []);

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "10%",
          paddingLeft: "15%",
          paddingRight: "15%",
        }}
      >
        <Box sx={{ minHeight: "70vh" }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "70%",
                    backgroundColor: "rgba(0, 0, 0,30%)",
                    justifyContent: "center",
                    display: "flex",
                    paddingTop: "1%",
                  }}
                >
                  <Box sx={{ width: "60%" }}>
                    {inoutFlag === "E" && (
                      <img
                        src={Dep}
                        width={"100%"}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ cursor: isHovered ? "pointer" : "default" }}
                        onClick={arrivalClick}
                      />
                    )}
                    {inoutFlag === "I" && (
                      <img
                        src={Arr}
                        width={"100%"}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ cursor: isHovered ? "pointer" : "default" }}
                        onClick={departureClick}
                      />
                    )}
                  </Box>
                </Paper>
                <Button
                  size="small"
                  sx={{
                    marginRight: "5%",
                    marginLeft: "auto",
                    border: 2,
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: 0,
                    height: 35,
                  }}
                  variant="outlined"
                  onClick={handleBoard}
                >
                  조 회
                </Button>
              </Box>

              <Paper
                elevation={3}
                sx={{
                  width: "95%",
                  backgroundColor: "rgba(255, 255, 255,20%)",
                  marginTop: "2%",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "40%", display: "flex" }}>
                  <Typography
                    sx={{
                      fontFamily: "'Noto Sans KR', sans-serif",
                      fontSize: "18px",
                      fontWeight: "bold",
                      paddingTop: "9%",
                      marginRight: "1%",
                      width: "30%",
                    }}
                  >
                    날짜
                  </Typography>
                  <TextField
                    id="dateCustField"
                    label="Date Select"
                    variant="standard"
                    value={txtDate ? moment(txtDate).format("YYYY-MM-DD") : ""}
                    onClick={dateCustClick}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    paddingBottom: "2%",
                    width: "60%",
                  }}
                >
                  <Typography
                    sx={{
                      ontFamily: "'Noto Sans KR', sans-serif",
                      fontSize: "18px",
                      fontWeight: "bold",
                      paddingTop: "7%",
                      marginRight: "5%",
                      width: "40%",
                    }}
                  >
                    항공사 코드
                  </Typography>
                  <TextField
                    id="carrierTextField"
                    label="Carrier Code"
                    variant="standard"
                    value={txtCarrier}
                    onChange={(e) => setCarrier(e.target.value)}
                    onClick={carrierTextClick}
                  />
                </Box>
              </Paper>
              <Box
                id="dateCustField"
                sx={{
                  marginTop: "2%",
                  display: dateCust === true ? "block" : "none",
                }}
                onClick={handleCalendarClick}
              >
                <CalenderCust props={changeTxtDate} />
              </Box>
            </Box>
            <Box sx={{ width: "60%" }}>
              <Paper
                elevation={3}
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(255, 255, 255,50%)",
                  marginTop: "7.5%",
                  minHeight: 400,
                }}
                onClick={handleCalendarClick}
              >
                <CarrierSelect
                  props={carrierData}
                  carrierCode={txtCarrierCode}
                  props2={txtCarrier}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openBoard}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Board props={list} />
        </Box>
      </Modal>
    </Box>
  );
}
