const apiUrl = process.env.REACT_APP_APIEAWB;

export const blSearchClick = async (blNum, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    if (blNum.length === 0) {
      throw new Error("B/L 번호 에러");
    }

    const response = await fetch(
      `${apiUrl}/eawb/getTraceList?bl=${blNum.replace("-", "")}`,
      {
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        const errorText = await response.text();
        throw new Error(errorText);
      } else {
        throw new Error(`${response.statusText}`);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "데이터없음" });
    } else {
      if (res.error !== "N") {
        throw new Error(res.data[0]);
      } else {
        const dataArray = res.data.map((row, index) => ({
          id: index,
          cargoSid: row.cargoSid,
          inoutFlag: row.inoutFlag,
          masterAirWayBillNo: row.masterAirWayBillNo,
          houseAirWayBillNo: row.houseAirWayBillNo,
        }));
        setLoadFlag(false);
        return dataArray;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "B/L조회 ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const traceResultAPI = async (cargoSid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/eawb/getStatusLog?cargoSid=${cargoSid}`,
      {
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        const errorText = await response.text();
        throw new Error(errorText);
      } else {
        throw new Error(`${response.statusText}`);
      }
    }
    const res = await response.json();

    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "No Data" });
    } else {
      if (res.error !== "N") {
        throw new Error(res.data[0]);
      } else {
        const dataArray = res.data.map((row, index) => ({
          id: index,
          mawb: row.mawb,
          hawb: row.hawb,
          fltDate: row.fltDate,
          assignCode: row.assignCode,
          orgin: row.orgin,
          location: row.location,
          pieces: row.pieces,
          weight: row.weight,
          status: row.status,
          statusLog: row.statusLog.map((logRow, logIndex) => ({
            logId: logIndex,
            cargoStatusTime: logRow.cargoStatusTime,
            cargoStatusName: logRow.cargoStatusName,
            remark: logRow.remark,
            remarkCode: logRow.remarkCode,
            deliverOrderNo: logRow.deliverOrderNo,
            deliveryOrderNoSub: logRow.deliveryOrderNoSub,
            customsAgentCode: logRow.customsAgentCode,
            consigneeName: logRow.consigneeName,
            noOfPackage: logRow.noOfPackage,
            netWeight: logRow.netWeight,
            masterAirWayBillNo: logRow.masterAirWayBillNo,
            houseAirWayBillNo: logRow.houseAirWayBillNo,
          })),
        }));
        setLoadFlag(false);
        return dataArray;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "추적조회ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getEawbFwbDown = async (mawb, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    if (String(mawb).includes("-", "").length === 0) {
      throw new Error("B/L정보가 없습니다.");
    }
    const response = await fetch(`${apiUrl}/eawb/mawb?bl=${mawb}`, {
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        const errorText = await response.text();
        throw new Error(errorText);
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const arrayBuffer = await response.arrayBuffer();
    setLoadFlag(false);
    return arrayBuffer;
  } catch (error) {
    setErrFlag({ code: "Y", data: "다운에러 : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getEawbFhlDown = async (
  trackDataArray,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    if (trackDataArray.length === 0) {
      throw new Error("B/L정보가 없습니다.");
    }
    let url = "";
    if (String(trackDataArray[0].hawb).length > 0) {
      url = `${apiUrl}/eawb/getfhl?mawb=&hawb=${trackDataArray[0].hawb}`;
    } else {
      url = `${apiUrl}/eawb/getfhl?mawb=${trackDataArray[0].mawb}&hawb=`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        const errorText = await response.text();
        throw new Error(errorText);
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const arrayBuffer = await response.arrayBuffer();
    setLoadFlag(false);
    return arrayBuffer;
  } catch (error) {
    setErrFlag({ code: "Y", data: "다운에러 : " + error.message });
  }
  setLoadFlag(false);
};
