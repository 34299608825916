import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { SaveButton, CancelButtonTablet } from "../util/CovMui";

export default function SpcTitleMobile({
  agencyFlag,
  saveFlag,
  onClickClose,
  saveClick,
  deleteClick,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#00114F",
          width: "100%",
          borderRadius: 8,
          mt: 2,
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontFamily: "Noto Sans KR",
            fontWeight: "500",
            fontSize: "1.2rem",
            ml: 3,
          }}
        >
          화물 등록
        </Typography>
      </Paper>
      <Box sx={{ textAlign: "right", mt: 2, mb: 1 }}>
        {agencyFlag === "Y" ? (
          <CancelButtonTablet variant="outlined" onClick={onClickClose}>
            닫기
          </CancelButtonTablet>
        ) : (
          <>
            {saveFlag === "U" ? (
              <>
                <SaveButton
                  onClick={saveClick}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  저장
                </SaveButton>
                <SaveButton
                  onClick={deleteClick}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  삭제
                </SaveButton>
              </>
            ) : (
              <SaveButton
                onClick={saveClick}
                variant="contained"
                sx={{ mr: 1 }}
              >
                저장
              </SaveButton>
            )}
            <CancelButtonTablet variant="contained" onClick={onClickClose}>
              닫기
            </CancelButtonTablet>
          </>
        )}
      </Box>
    </Box>
  );
}
