import {
  Box,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ScheduleBack from "../images/grey_img.jpg";
import Arr from "../images/입항출항_Arr.png";
import Dep from "../images/입항출항_Dep.png";
import { useState } from "react";
import { useEffect } from "react";
import CalenderCust from "../CalenderCust";
import moment from "moment";
import BoardMobile from "./BoardMobile";
import CarrierSelectMobile from "./CarrierSelectMobile";
import { getCarrierCode, getSch } from "../API/ScheduleAPI";
import { useContext } from "react";
import { AppContext } from "../AppContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "1%",
};

export default function CargoScheduleMobile() {
  const [inoutFlag, setInoutFlag] = useState("I");
  const [isHovered, setIsHovered] = useState(false);
  const [carrierList, setCarrierList] = useState(false);
  const [dateCust, setDateCust] = useState(false);
  const [txtDate, setTxtDate] = useState("");
  const [txtCarrier, setCarrier] = useState("");
  const [openBoard, setOpenBoard] = useState(false);
  const [carrierData, setCarrierData] = useState([]);
  const [fatchCarrierData, setFatchCarrierData] = useState("");
  const [list, setList] = useState([]);
  const { setErrFlag, setLoadFlag, setCompleteCode } = useContext(AppContext);
  const boardClick = (props) => {
    if (props) {
    } else {
      setOpenBoard(false);
    }
  };

  const arrivalClick = () => {
    setInoutFlag("I");
  };

  const departureClick = () => {
    setInoutFlag("E");
  };

  const carrierTextClick = () => {
    setCarrierList(true);
    setDateCust(false);
  };
  const dateCustClick = () => {
    setDateCust(true);
    setCarrierList(false);
  };
  const changeTxtDate = (props) => {
    setTxtDate(props);
  };
  const handleBoard = () => {
    const fltDate = moment(txtDate).format("YYYYMMDD");
    getSch(inoutFlag, fltDate, fatchCarrierData, setErrFlag, setLoadFlag)
      .then((result) => {
        if (result) {
          setList(result);
          setTxtDate("");
          setCarrier("");
        }
      })
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  useEffect(() => {
    if (list.length > 0) {
      setOpenBoard(true);
    } else {
      setOpenBoard(false);
    }
  }, [list]);
  const handleClose = () => setOpenBoard(false);

  const txtCarrierCode = (props) => {
    if (props.carrierCode.length > 0) {
      setCarrierList(false);
      setCarrier(props.id);
      setFatchCarrierData(props.carrierCode);
    }
  };
  useEffect(() => {
    setTxtDate("");
    setInoutFlag("I");
    getCarrierCode(setErrFlag, setLoadFlag)
      .then((result) => result && setCarrierData(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#carrierTextField") &&
        !event.target.closest("#terminalButton")
      ) {
        setCarrierList(false);
      }

      if (!event.target.closest("#dateCustField")) {
        setDateCust(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [carrierList, dateCust]);
  const handleCalendarClick = (event) => {
    // 캘린더 내부를 클릭한 경우에는 컴포넌트가 사라지지 않도록 함
    event.stopPropagation();
  };

  const getKorNameById = (id) => {
    const foundCarrier = carrierData.find((item) => item.id === id);
    return foundCarrier ? foundCarrier.carrierCode : "Carrier Code";
  };
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${ScheduleBack})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "22%",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <Box sx={{ minHeight: 800 }}>
          <Box sx={{ display: "block" }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "55%",
                    backgroundColor: "rgba(0, 0, 0,30%)",
                    justifyContent: "center",
                    display: "flex",
                    paddingTop: "1%",
                  }}
                >
                  <Box sx={{ width: "60%" }}>
                    {inoutFlag === "E" && (
                      <img
                        src={Dep}
                        width={"100%"}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ cursor: isHovered ? "pointer" : "default" }}
                        onClick={arrivalClick}
                      />
                    )}
                    {inoutFlag === "I" && (
                      <img
                        src={Arr}
                        width={"100%"}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ cursor: isHovered ? "pointer" : "default" }}
                        onClick={departureClick}
                      />
                    )}
                  </Box>
                </Paper>
                <Button
                  size="small"
                  sx={{
                    marginRight: "5%",
                    marginLeft: "auto",
                    border: 2,
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: 0,
                    height: 25,
                    color: "black",
                  }}
                  variant="outlined"
                  onClick={handleBoard}
                >
                  조 회
                </Button>
              </Box>

              <Paper
                elevation={3}
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(255, 255, 255,20%)",
                  marginTop: "2%",
                  display: "flex",
                  padding: "1%",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "'Plus Jakarta Sans', sans-serif",
                      fontSize: "10px",
                      fontWeight: "bold",
                      marginRight: "1%",
                      width: "30%",
                    }}
                  >
                    날짜
                  </Typography>
                  <Button
                    sx={{
                      color: "black",
                      borderBottom: 2,
                      borderRadius: 0,
                      width: "100%",
                      fontSize: "10px",
                      textAlign: "left",
                    }}
                    onClick={(e) => (e.stopPropagation(), dateCustClick())}
                  >
                    {txtDate !== ""
                      ? moment(txtDate).format("YYYY-MM-DD")
                      : "년-월-일"}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "50%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "'Plus Jakarta Sans', sans-serif",
                      fontSize: "10px",
                      fontWeight: "bold",
                      marginRight: "5%",
                      width: "30%",
                    }}
                  >
                    항공사
                  </Typography>
                  <Button
                    sx={{
                      color: "black",
                      borderBottom: 2,
                      borderRadius: 0,
                      width: "100%",
                      fontSize: "10px",
                      textAlign: "left",
                    }}
                    onClick={(e) => (e.stopPropagation(), carrierTextClick())}
                  >
                    {getKorNameById(txtCarrier)}
                  </Button>
                </Box>
              </Paper>
              <Box
                id="dateCustField"
                sx={{
                  marginTop: "2%",
                  display: dateCust === true ? "block" : "none",
                  width: "80%",
                }}
                onClick={handleCalendarClick}
              >
                <CalenderCust props={changeTxtDate} />
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Paper
                elevation={3}
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(255, 255, 255,50%)",
                  marginTop: "5%",
                  minHeight: 400,
                  display: carrierList ? "block" : "none",
                }}
                onClick={handleCalendarClick}
              >
                <CarrierSelectMobile
                  props={carrierData}
                  carrierCode={txtCarrierCode}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openBoard}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BoardMobile props={list} props2={boardClick} />
        </Box>
      </Modal>
    </Box>
  );
}
